import { Card } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import { useLocation, useNavigate } from "react-router-dom";
import { IPyeongtaek } from "apis/response";
import { paramsFilter } from "utils/ParamsFilter";
import Apis from "apis/remotes";

function PyeongtaekStatisticsList() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [notices, setNotices] = useState<IPyeongtaek[]>([]);
  const [pyeongtaekUserTotalCount, setPyeongtaekUserTotalCount] = useState(0);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/utility-management/pyeongtaek-statistics-list${paramsFilter({
        page,
      })}`
    );
    setCurrentPage(page);
  };
  useEffect(() => {
    Apis.getPyeongtaek({ page: currentPage, perPage: 10 })
      .then((resolve) => {
        setNotices(resolve.data.data);
        setTotal(resolve.data.total);
        setPyeongtaekUserTotalCount(resolve.data.pyeongtaekUserTotalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.pyeongtaekUserDay}
            scroll={{ x: "max-content" }}
            dataSource={notices}
            columns={tableColumn}
            title={() => {
              return <div>총합: {pyeongtaekUserTotalCount}명</div>;
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default PyeongtaekStatisticsList;
