import { Card, Divider } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import ListItem from "./components/ListItem";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { Form, Modal, Switch, message } from "antd";
import { ICoupon } from "apis/response";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import UserSearchForm from "./components/UserSearchForm";
import GiveCoupon from "./components/GiveCoupon";

function CouponDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [coupon, setCoupon] = useState<ICoupon>();
  const [form] = useForm();

  const [allPaymentModal, setAllPaymentModal] = useState(false);
  const [allPaymentButtonDisabled, setAllPaymentButtonDisabled] = useState(false);

  const [eachPaymentModal, setEachPaymentModal] = useState(false);

  /** 쿠폰 삭제 버튼 클릭 */
  const handleDeleteCoupon = () => {
    Apis.deleteCoupon(id)
      .then((resolve) => {
        navigate("/coupon-management/coupon-list");
        message.success("쿠폰이 제거되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateCouponEnabled = (couponId: number) => {
    Apis.putCouponById(couponId)
      .then((resolve) => {
        message.success("성공적으로 업데이트 되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const SendAllCoupon = () => {
    setAllPaymentButtonDisabled(true);

    Apis.postCouponDistributeAll(id)
      .then((resolve) => {
        message.success("성공적으로 쿠폰이 지급되었습니다.");
        setAllPaymentModal(false);
        setAllPaymentButtonDisabled(false);
      })
      .catch((error) => {
        console.log(error);
        setAllPaymentModal(false);
        setAllPaymentButtonDisabled(false);
      });
  };

  useEffect(() => {
    Apis.getCouponById(id)
      .then((resolve) => {
        setCoupon(resolve.data);
        form.setFieldValue("enabled", resolve.data.enabled);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox p={2}>
          <MDTypography variant="h5">쿠폰 상세 정보</MDTypography>
          <Divider />
          <ListItem label={"이름"} values={coupon?.couponTitle} />
          <ListItem label={"설명"} values={coupon?.couponContent} />
          <ListItem label={"코드"} values={coupon?.code} />
          <ListItem label={"타입"} values={coupon?.couponType} />
          <ListItem label={"가격"} values={coupon?.value} />
          <Form form={form}>
            <FormItem
              label={
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  활성 상태
                </MDTypography>
              }
              name="enabled"
              valuePropName="checked"
              initialValue={coupon?.enabled}
            >
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                onChange={() => handleUpdateCouponEnabled(coupon?.couponId)}
              />
            </FormItem>
          </Form>
        </MDBox>
        <MDBox p={3} display={"flex"} justifyContent={"end"} gap={2}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setAllPaymentModal(!allPaymentModal);
            }}
            disabled={allPaymentButtonDisabled}
          >
            전체 지급
          </MDButton>
          <Modal
            title="쿠폰 전체 지급"
            open={allPaymentModal}
            onCancel={() => setAllPaymentModal(false)}
            onOk={SendAllCoupon}
            okText="지급"
            cancelText="취소"
          >
            <p>정말로 전체에게 해당 쿠폰을 지급하시겠습니까?</p>
          </Modal>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setEachPaymentModal(!eachPaymentModal);
            }}
          >
            개별 지급
          </MDButton>
          <MDButton color="error" size="small" onClick={() => handleDeleteCoupon()}>
            쿠폰 삭제
          </MDButton>
        </MDBox>
      </Card>
      <Divider />
      {eachPaymentModal && <GiveCoupon couponId={id} />}
    </DashboardLayout>
  );
}
export default CouponDetails;
