import { Card } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Apis from "apis/remotes";
import { IInsuranceStatics } from "apis/response";
// import AntTable from "./components/table";

import { Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
// import { tableColumn } from "./components/data";

import { ColumnsType } from "antd/es/table";

function InsuranceStatisticsList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IInsuranceStatics[]>([]);
  const [insuranceUserTotalCount, setInsuranceUserTotalCount] = useState(0);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/utility-management/insurance-statistics-list${paramsFilter({
        page,
      })}`
    );
  };

  useEffect(() => {
    Apis.getInsuranceStatics({ page: currentPage, perPage: 10 })
      .then((resolve) => {
        console.log(resolve.data);
        setData(resolve.data.data);
        setTotal(resolve.data.total);
        setInsuranceUserTotalCount(resolve.data.insuranceUserTotalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);
  const tableColumn: ColumnsType<IInsuranceStatics> = [
    {
      title: "날짜",
      dataIndex: "insuranceUserDay",
      ellipsis: true,
      render: (insuranceUserDay: string) => (
        <MDBox style={{ maxWidth: 200 }}>{insuranceUserDay}</MDBox>
      ),
    },
    {
      title: "고용보험 인증 회원",
      dataIndex: "insuranceUserDayCount",
      ellipsis: true,
      render: (insuranceUserDayCount: number) => (
        <MDBox style={{ maxWidth: 200 }}>{insuranceUserDayCount}명</MDBox>
      ),
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.insuranceUserDay}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(
                    `/utility-management/insurance-statistics-detail/${record.insuranceUserDay}`
                  );
                },
              };
            }}
            title={() => {
              return <div>총합: {insuranceUserTotalCount}명</div>;
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default InsuranceStatisticsList;
