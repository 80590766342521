import { Card, Grid } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import Apis from "apis/remotes";
import { IGetAdministrators } from "apis/response";
import { paramsFilter } from "utils/ParamsFilter";

function AdminList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");
  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));

  const [data, setData] = useState<IGetAdministrators[]>([]);
  const [total, setTotal] = useState(0);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/admin-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/admin-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };
  useEffect(() => {
    Apis.getAdministrator({ page: currentPage, perPage: 10, search, sort, order })
      .then((resolve) => {
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3} spacing={2}>
          <Grid item xs={12} sm={2}>
            {/* <MDButton variant="outlined" color="dark">
              엑셀 다운
            </MDButton> */}
          </Grid>
          <Grid item xs={12} sm={3}></Grid>

          <Grid item xs={6} sm={5}>
            <Search
              maxLength={30}
              placeholder="이름을 검색하세요"
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <MDBox display="flex" justifyContent="end">
              <MDButton variant="gradient" color="info" LinkComponent={Link} to={"/new-admin"}>
                관리자 추가
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.adminId}
            dataSource={data}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/admin-list/${record.adminId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default AdminList;
