import { Card, Divider } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import ListItem from "./components/ListItem";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { IBannerById } from "apis/response";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";
import MDBadgeDot from "components/MDBadgeDot";
import { message } from "antd";
import MDImage from "components/MDImage";

function BannerDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [banner, setBanner] = useState<IBannerById>();
  const [loading, setLoading] = useState(false);
  /** 배너 삭제 버튼 클릭 */
  const handleDelteBanner = () => {
    setLoading(true);
    Apis.deleteBanner(id)
      .then((resolve) => {
        message.success("배너가 제거되었습니다.");
        navigate("/member-management/banner-list");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  /** 배너 On/Off 버튼 클릭 */
  const handleUpdateOffBanner = () => {
    setLoading(true);
    Apis.putBannerSwitch(id)
      .then((resolve) => {
        message.info("배너 상태가 변경되었습니다.");
        setBanner((prev) => {
          return { ...prev, isDisabled: prev.isDisabled === 1 ? 0 : 1 };
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  /** 배너 수정 버튼 클릭 */
  const handleNavigateUpdateBanner = () => {
    navigate(`/member-management/update-banner-details/${id}`);
  };
  useEffect(() => {
    Apis.getBannerById(id)
      .then((resolve) => {
        setBanner(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <MDBox mb={2} lineHeight={1} display={"flex"} justifyContent={"space-between"}>
              <MDTypography variant="h3">배너 상세 조회</MDTypography>
              <MDButton color="info" size="small" onClick={() => handleNavigateUpdateBanner()}>
                배너 수정
              </MDButton>
            </MDBox>
            <MDBox opacity={0.3}>
              <Divider />
            </MDBox>

            <MDBox>
              <MDImage image={banner?.image} alt="content_img" width={300} />
              <ListItem label={"본문내용"} values={banner?.description} />
              <ListItem
                label={"배너 상태"}
                values={
                  banner?.isDisabled ? (
                    <MDBadgeDot badgeContent="OFF" color="dark" />
                  ) : (
                    <MDBadgeDot badgeContent="ON" color="success" />
                  )
                }
              />
            </MDBox>
          </MDBox>
          <MDBox p={3} display={"flex"} justifyContent={"end"} gap={2}>
            <MDButton
              disabled={loading}
              color={banner?.isDisabled ? "success" : "warning"}
              size="small"
              onClick={() => handleUpdateOffBanner()}
            >
              {banner?.isDisabled ? "배너 활성화" : "배너 비활성화"}
            </MDButton>
            <MDButton
              disabled={loading}
              color="error"
              size="small"
              onClick={() => handleDelteBanner()}
            >
              배너 삭제
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default BannerDetails;
