import { Card } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import { useLocation, useNavigate } from "react-router-dom";
import { IChat } from "apis/response";
import { paramsFilter } from "utils/ParamsFilter";
import Apis from "apis/remotes";

function BuddyChatList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");
  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [workChats, setWorkChats] = useState<IChat[]>([]);
  const [total, setTotal] = useState(0);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/chat-management/work-chat-list${paramsFilter({
        page,
      })}`
    );
    setCurrentPage(page);
  };
  useEffect(() => {
    Apis.getBuddyChats({ page: currentPage, perPage: 10 })
      .then((resolve) => {
        setWorkChats(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <Table
            rowKey={(record) => record.roomId}
            scroll={{ x: "max-content" }}
            dataSource={workChats}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/chat-management/chat-details/${record.roomId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default BuddyChatList;
