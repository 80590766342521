import { ColumnsType } from "antd/es/table";
import { IWorkFieldEvaluation } from "apis/response";
import MDBadge from "components/MDBadge";

const tableColumn: ColumnsType<IWorkFieldEvaluation> = [
  {
    title: "현장명",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
    render: (title, record) => {
      return (
        <>
          {record.issue && <MDBadge badgeContent="특이사항" container size="sx" color="error" />}
          {" " + title}
        </>
      );
    },
  },
  {
    title: "현장근무날짜",
    dataIndex: "workDay",
    key: "workDay",
    ellipsis: true,
  },
];
export { tableColumn };
