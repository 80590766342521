import { ColumnsType } from "antd/es/table";
import { IUtilImages } from "apis/response";

import MDImage from "components/MDImage";

const tableColumn: ColumnsType<IUtilImages> = [
  {
    title: "내용",
    dataIndex: "image",
    key: "image",
    ellipsis: true,
    render: (image: string) => (
      <MDImage image={image} style={{ width: 50, objectFit: "contain" }} />
    ),
  },
  {
    title: "등록시간",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
  },
];
export { tableColumn };
