import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import MDButton from "components/MDButton";
import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";
import Apis from "apis/remotes";
import { IGetBanUsers, USERSTATUS } from "apis/response";

import { message, Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";

import { inputToPhone } from "utils/PhoneString";
import { emptyString } from "utils";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";

function BlackUserList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IGetBanUsers[]>([]);

  const handlePutUserBanById = (adminBanId: string | number) => {
    Apis.putUserBanById(adminBanId)
      .then((resolve) => {
        message.success("사용 정지가 해제되었습니다.");
        setData((prev) => prev.filter((data) => data.adminBanId !== adminBanId));
        setCurrentPage(1);
        navigate(
          `/member-management/black-user-list${paramsFilter({
            sort,
            order,
            search,
            page,
          })}`
        );
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.message);
      });
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/member-management/black-user-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/member-management/black-user-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };

  useEffect(() => {
    Apis.getBanUsers({ page: currentPage, perPage: 10, search, sort, order })
      .then((resolve) => {
        // console.log(resolve.data);
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order]);

  const tableColumn: ColumnsType<IGetBanUsers> = [
    {
      title: "이름",
      dataIndex: "name",
      ellipsis: true,
      render: (name: string) => emptyString(name),
    },
    {
      title: "핸드폰번호",
      dataIndex: "phone",
      ellipsis: true,
      render: (phone: string) => inputToPhone(phone),
    },

    {
      title: "정지기간",
      dataIndex: "adminBanDay",
      ellipsis: true,
      render: (adminBanDay: string) => {
        if (adminBanDay === "week") {
          return "1주일 정지";
        } else if (adminBanDay === "permanent") {
          return "영구 정지";
        } else {
          return emptyString(adminBanDay); // 다른 값이나 빈 값인 경우 처리
        }
      },
    },
    {
      title: "정지사유",
      dataIndex: "adminBanReason", // "secondAddress"를 추가 정보로 표시할 수 있도록 고려해야 할 수 있습니다.
      ellipsis: true,

      render: (adminBanReason: string) => emptyString(adminBanReason),
    },
    {
      title: "정지 날짜",
      dataIndex: "createdAt",
      ellipsis: true,
      render: (createdAt: string) => dayjs(createdAt).format("YYYY-MM-DD"),
    },
    {
      title: "정지 관리",
      dataIndex: "status",
      ellipsis: true,
      align: "center",
      render: (status, record) => {
        //뭐시기 값 있으면 분기해서 풀어주는 기능 있어야함.
        if (status === "ban") {
          return (
            <MDButton
              size="small"
              color="warning"
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();

                handlePutUserBanById(record.adminBanId);
              }}
            >
              정지 해제
            </MDButton>
          );
        } else {
          return (
            <MDButton size="small" color="dark" disabled>
              정지 유저
            </MDButton>
          );
        }
      },
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3} spacing={2}>
          <Grid item xs={12} md={4}>
            {/* target="_blank"의 문제점
            1. 보안상 취약점이 생긴다

            2. 퍼포먼스가 떨어질 수 있다
            target="_blank"가 사용된 링크에 의해 열린 링크된 페이지는 링크를 건 페이지와 같은
            프로세스를 통해서 실행됩니다. 그러므로 가령 링크된 페이지에서 높은 부하를 유발하는
            JavaScript가 실행되고 있으면 링크를 건 페이지에도 그 영향이 미쳐져 의도하지 않게
            퍼포먼스가 떨어지는 문제가 발생할 수도 있습니다.
             */}
            {/* target="_blank"의 문제점 해결 방법

            1. noopener 지정
            noopener(노오프너)를 지정하면, 링크된 페이지에서 window.opener을 사용해서 링크를 건
            페이지를 참조(reference)할 수 없게 됩니다. 더불어 링크된 페이지와 링크를 건 페이지는
            별개의 프로세스로 취급되기 때문에 서로 연결되어 퍼포먼스가 떨어지는 일도 없게 됩니다.

            2. noreferrer 지정
            noreferrer(노리퍼러)를 지정하면 다른 페이지로 이동할 때, 링크를 건
            페이지의 주소 등의 정보를 브라우저가 Referer: HTTP 헤더로 리퍼러(referer 또는
            referrer)로서 송신하지 않습니다.
             */}
            {/* <a
              href={`${process.env.REACT_APP_ENDPOINT}user/data/download`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDButton endIcon={<Icon>downloadIcon</Icon>} variant="gradient" color="info">
                회원 정보 엑셀 다운
              </MDButton>
            </a> */}
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <MDBox display="flex" gap={1} justifyContent="end">
              <Select
                style={{ minWidth: 150 }}
                // defaultValue={city}
                value={city || null}
                placeholder="시/도 선택"
                options={[{ label: "전체", value: null }, ...cityList]}
                onChange={handleCityFilter}
              />

              <Select
                style={{ minWidth: 150 }}
                placeholder="시/군/구 선택"
                // defaultValue={district}
                value={district || null}
                disabled={districtList.length ? false : true}
                options={[{ label: "전체", value: null }, ...districtList]}
                onChange={handleDistrictFilter}
              />
            </MDBox> */}
          </Grid>
          <Grid item xs={12} md={4}>
            <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.adminBanId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/member-management/member-details/${record.userId}`);
            //     },
            //   };
            // }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default BlackUserList;
