import { ColumnsType } from "antd/es/table";
import { IUtilDetails } from "apis/response";
import MDBox from "components/MDBox";

const tableColumn: ColumnsType<IUtilDetails> = [
  {
    title: "지원 시간",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
    render: (title: string) => <MDBox style={{ maxWidth: 200 }}>{title}</MDBox>,
  },
  {
    title: "지원자",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    render: (name: string) => <MDBox style={{ maxWidth: 200 }}>{name}</MDBox>,
  },
];
export { tableColumn };
