import { ColumnsType } from "antd/es/table";
import { IPyeongtaek } from "apis/response";
import MDBox from "components/MDBox";

const tableColumn: ColumnsType<IPyeongtaek> = [
  {
    title: "날짜",
    dataIndex: "pyeongtaekUserDay",
    key: "pyeongtaekUserDay",
    ellipsis: true,
    render: (pyeongtaekUserDay: string) => (
      <MDBox style={{ maxWidth: 200 }}>{pyeongtaekUserDay}</MDBox>
    ),
  },

  {
    title: "신규 가입 회원",
    dataIndex: "pyeongtaekUserDayCount",
    key: "pyeongtaekUserDayCount",
    ellipsis: true,
    render: (pyeongtaekUserDayCount: number) => (
      <MDBox style={{ maxWidth: 200 }}>{pyeongtaekUserDayCount}명</MDBox>
    ),
  },
];

export { tableColumn };
