import { ColumnsType } from "antd/es/table";

import { IOfferUser } from "apis/response";
import { inputToPhone } from "utils/PhoneString";
import { calculateAge } from "utils/utilDate";
import RenderUserScore from "components/RenderUserScore";
import { genderString } from "utils";

const tableColumn: ColumnsType<IOfferUser> = [
  {
    title: "이름",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "평점",
    dataIndex: "userScore",
    ellipsis: true,
    render: RenderUserScore,
  },
  {
    title: "지역",
    dataIndex: "district",
    ellipsis: true,
    render: (district, record) =>
      (record?.city === "" ? "미입력" : record?.city) +
      "/" +
      (district === "" ? "미입력" : district),
  },
  {
    title: "나이",
    dataIndex: "birth",
    ellipsis: true,
    render: (birth: string) => calculateAge(birth),
  },
  {
    title: "혈압",
    dataIndex: "bloodPressure",
    key: "bloodPressure",
    ellipsis: true,
  },
  {
    title: "연락처",
    dataIndex: "phone",
    ellipsis: true,
    render: (phone: string) => inputToPhone(phone),
  },
  {
    title: "성별",
    dataIndex: "gender",
    ellipsis: true,
    render: (gender: string) => genderString(gender),
  },
  {
    title: "근로 횟수",
    dataIndex: "workCount",
    ellipsis: true,
  },
];

export { tableColumn };
