import { Divider, Grid, Typography } from "@mui/material";
import { Card, message } from "antd";
import Apis from "apis/remotes";
import { IOfferUser, IWorkById } from "apis/response";
import MDBox from "components/MDBox";
import MDImage from "components/MDImage";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ListItem from "../ListItem";
import dayjs from "dayjs";
import { inputToPhone } from "utils/PhoneString";

interface IWorkProps {
  announcement: IWorkById;
}

function Work({ announcement }: IWorkProps): JSX.Element {
  return (
    <Card>
      <MDBox p={2}>
        <MDBox display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h5">{announcement?.address}</Typography>
          <Typography variant="h5">
            {dayjs(announcement?.workDay).format("YYYY년 MM월 DD일")}
          </Typography>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <MDImage
                image={announcement?.image}
                style={{
                  width: "100%",
                  maxWidth: 600,
                  borderRadius: 12,
                  height: "100%",
                  maxHeight: 320,
                  objectFit: "cover",
                }}
                alt="site_thumb_nail"
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={4}>
              <ListItem label={"원청"} values={announcement?.company ?? ""} />
              <ListItem
                label={"공고 담당자"}
                values={inputToPhone(announcement?.managerName ?? "")}
              />
              <ListItem
                label={"공고 담당자 연락처"}
                values={inputToPhone(announcement?.managerPhone ?? "")}
              />
              <ListItem
                label={"현장 담당자"}
                values={inputToPhone(announcement?.fieldManagerName ?? "")}
              />
              <ListItem
                label={"현장 담당자 연락처"}
                values={inputToPhone(announcement?.fieldManagerPhone ?? "")}
              />
              <MDBox opacity={0.3}>
                <Divider />
              </MDBox>
              <ListItem label={"공고제목"} values={announcement?.title ?? ""} />
              <ListItem
                label={"공고게시기간"}
                values={announcement?.workStart + " ~ " + announcement?.workEnd}
              />
              <ListItem
                label={"근무일/근무시간"}
                values={
                  dayjs(announcement?.workDay).format("YYYY-MM-DD") + " / " + announcement?.workTime
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem label={"공수"} values={announcement?.manDay ?? ""} />
              <ListItem
                label={"급여"}
                values={(announcement?.pay.toLocaleString() ?? "0") + "원"}
              />
              <ListItem label={"급여형태"} values={announcement?.payType ?? ""} />
              <ListItem label={"지역"} values={announcement?.address ?? ""} />
              <ListItem label={"내용"} values={announcement?.content ?? ""} />
              <ListItem label={"경력"} values={announcement?.requiredExperience ?? ""} />
              <ListItem label={"모집인원"} values={announcement?.maxRecruitment ?? 0} />
              <ListItem label={"확정인원"} values={announcement?.currentRecruitment ?? "0"} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Work;
