import { Card } from "@mui/material";
import { message } from "antd";
import Apis from "apis/remotes";
import { IGetAdminById } from "apis/response";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListItem from "./components/ListItem";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import { inputToPhone } from "utils/PhoneString";

function AdminDetails(): JSX.Element {
  const { id } = useParams();
  const [admin, setAdmin] = useState<IGetAdminById>();
  // const handleDeleteAdmin = () => {
  //   Apis.deleteAdministrator(id)
  //     .then((resolve) => {
  //       message.warning("계정을 삭제하였습니다.");
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data.message ?? "");
  //     });
  // };
  useEffect(() => {
    Apis.getAdministratorById(id)
      .then((resolve) => {
        setAdmin(resolve.data);
      })
      .catch((error) => {
        console.log(error.response.data.message ?? "");
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <ListItem label={"이름"} values={admin?.nickName} />
            <ListItem label={"이메일"} values={admin?.email} />
            <ListItem label={"연락처"} values={inputToPhone(admin?.phone ?? "")} />
            <ListItem label={"상태"} values={admin?.status} />
            <ListItem label={"권한"} values={RolBadge(admin?.role)} />
            <ListItem label={"생성일"} values={admin?.createdAt} />
          </MDBox>
        </Card>
        {/* 해당 기능은 필요없음. */}
        {/* <MDBox p={2} display={"flex"} justifyContent={"end"}>
          <MDButton color="error" onClick={() => handleDeleteAdmin()}>
            관리자 계정 삭제
          </MDButton>
        </MDBox> */}
      </MDBox>
    </DashboardLayout>
  );
}
function RolBadge(value: string) {
  switch (value) {
    case "master":
      return <MDBadge badgeContent="Admin" size="xs" container color="secondary" />;
    case "user":
      return <MDBadge badgeContent="User" size="xs" container color="info" />;
    default:
      return <MDBadge badgeContent="Not" size="xs" container color="warning" />;
  }
}

export default AdminDetails;
