import { Card, Grid } from "@mui/material";
import { DatePicker, Pagination, PaginationProps, Table, message } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { ICouponSettlement } from "apis/response";
import Apis from "apis/remotes";
import { paramsFilter } from "utils/ParamsFilter";
import STcomp from "components/STcomp";
import RenderUserScore from "components/RenderUserScore";
import { calculateAge } from "utils/utilDate";
import { ColumnsType } from "antd/es/table";

function CouponSettlementList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));

  const [couponSettlement, setCouponSettlement] = useState<ICouponSettlement[]>([]);
  const [totalResult, setTotalResult] = useState({
    total: 0,
    totalPaidCouponSettlementUserCount: 0,
    totalPaidCouponAmount: 0,
    totalCouponAmount: 0,
  });

  const [fetchDataTrigger, setFetchDataTrigger] = useState(false);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/settlement-management/coupon-settlement-history${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/settlement-management/coupon-settlement-history${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };

  async function handleDeposit(e: any, settlementId: number) {
    Apis.putSettlementDepositCouponById(settlementId)
      .then((resolve) => {
        message.success("입금완료 처리되었습니다.");
        setFetchDataTrigger(!fetchDataTrigger);
      })
      .catch((error) => {
        message.error("입금완료 처리에 실패했습니다.");
      });
  }

  const tableColumn: ColumnsType<ICouponSettlement> = [
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "평점",
      dataIndex: "userScore",
      key: "userScore",
      ellipsis: true,
      align: "center",
      render: RenderUserScore,
    },
    {
      title: "나이",
      dataIndex: "birth",
      key: "birth",
      ellipsis: true,
      align: "center",
      render: (birth: string) => calculateAge(birth),
    },
    {
      title: "전화번호",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
    },
    {
      title: "공고명",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },

    {
      title: "계좌번호",
      dataIndex: "bankAccount",
      key: "bankAccount",
      ellipsis: true,
    },
    {
      title: "은행명",
      dataIndex: "bankName",
      key: "bankName",
      ellipsis: true,
      align: "center",
    },
    {
      title: "예금주",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "center",
    },
    {
      title: "쿠폰 제목",
      dataIndex: "couponTitle",
      key: "couponTitle",
      ellipsis: true,
      align: "center",
    },
    {
      title: "쿠폰 금액",
      dataIndex: "couponAmount",
      key: "couponAmount",
      ellipsis: true,
      align: "right",
      render: (couponAmount: number) => `${couponAmount?.toLocaleString()}원`,
    },
    {
      title: "입금 여부",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      align: "center",
      render: (status: string, record: ICouponSettlement) =>
        status === "입금완료" ? (
          <MDButton disabled>입금완료</MDButton>
        ) : (
          <MDButton
            variant="contained"
            color="primary"
            onClick={(e) => {
              handleDeposit(e, record.couponSettlementId);
            }}
          >
            미입금
          </MDButton>
        ),
    },
    {
      title: "입금확인시간",
      dataIndex: "paidAt",
      key: "paidAt",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    Apis.getCouponSettlement({ page: currentPage, perPage: 10, search, sort, order })
      .then((resolve) => {
        console.log(resolve.data.data);
        setCouponSettlement(resolve.data.data);
        setTotalResult({
          total: resolve.data.total,
          totalPaidCouponSettlementUserCount: resolve.data.totalPaidCouponSettlementUserCount,
          totalPaidCouponAmount: resolve.data.totalPaidCouponAmount,
          totalCouponAmount: resolve.data.totalCouponAmount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order, fetchDataTrigger]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        {/* <Grid container py={2} spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker.RangePicker />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Search
              maxLength={30}
              placeholder="파트너의 이름 또는 현장명을 검색하세요"
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
        </Grid> */}
        <Grid container pb={2}>
          <Grid item xs={12} sm={12}>
            <MDBox display={"flex"} justifyContent={"space-between"} width={"100%"}>
              <STcomp title={"정산 필요인원"} amount={totalResult.total} variant="contained" />
              <STcomp
                title={"정산 완료인원"}
                amount={totalResult.totalPaidCouponSettlementUserCount}
                variant="contained"
              />
              <STcomp
                title={"누적 쿠폰 지급액"}
                amount={`${totalResult.totalPaidCouponAmount.toLocaleString()}원`}
                variant="contained"
              />
              <STcomp
                title={"총 쿠폰 지급액"}
                amount={`${totalResult.totalCouponAmount.toLocaleString()}원`}
                variant="contained"
              />
            </MDBox>
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.couponSettlementId}
            scroll={{ x: "max-content" }}
            dataSource={couponSettlement}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/settlement-management/settlement-history-detail/${record.id}`);
            //     },
            //   };
            // }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={totalResult.total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default CouponSettlementList;
