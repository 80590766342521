import { ColumnsType } from "antd/es/table";
import { IChat } from "apis/response";

const tableColumn: ColumnsType<IChat> = [
  {
    title: "제목",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
  },
  {
    title: "생성시간",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
  },
];

export { tableColumn };
