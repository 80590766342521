import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { IGetUserAbsence } from "apis/response";

import { Form, Input, Radio } from "antd";
import FormItem from "antd/es/form/FormItem";

interface Props {
  user: IGetUserAbsence;
  open: boolean;
  handleOpenState: Function;
  handleSubmitAbsenceUser: Function;
}

function WokerAbsenceDialog({
  user,
  open,
  handleOpenState,
  handleSubmitAbsenceUser,
}: Props): JSX.Element {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmitAbsenceUser(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Dialog open={open} onClose={() => handleOpenState()} fullWidth>
      <DialogTitle>
        <MDBox display="flex" justifyContent="center">
          결근자 사용 정지하기
        </MDBox>
      </DialogTitle>
      <DialogContent>
        <MDBox mb={3}>
          <MDTypography variant="h6" gutterBottom>
            사용자 정보
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="h6">
              <strong>이름:</strong> {user?.name}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="h6">
              <strong>핸드폰 번호:</strong> {user?.phone}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="h6">
              <strong>주소:</strong> {user?.firstAddress} {user?.secondAddress}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="h6">
              <strong>생년월일:</strong> {user?.birth}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox mb={3}>
          <Form
            autoComplete="off"
            form={form}
            initialValues={{ userId: user?.userId ?? 0, adminBanDay: "week" }}
          >
            <FormItem hidden name="userId">
              <Input hidden />
            </FormItem>
            <FormItem
              name="adminBanDay"
              label={<MDTypography variant="h6">정지기간</MDTypography>}
              rules={[{ required: true, message: "정지기간을 선택하세요." }]}
            >
              <Radio.Group>
                <Radio value="week">1주일 정지</Radio>
                <Radio value="permanent">영구 정지</Radio>
              </Radio.Group>
            </FormItem>
            <FormItem
              name="adminBanReason"
              label={<MDTypography variant="h6">정지사유</MDTypography>}
              rules={[{ required: true, message: "정지 사유를 입력하세요." }]}
            >
              <Input maxLength={250} showCount />
            </FormItem>
          </Form>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => handleOpenState()}>취소</MDButton>
        <MDButton onClick={handleSubmit} color="info">
          제출하기
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default WokerAbsenceDialog;
