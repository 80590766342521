import { Icon } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { INewSettlementById } from "apis/response";
import MDBox from "components/MDBox";
import RenderUserScore from "components/RenderUserScore";
import { calculateAge } from "utils/utilDate";

const tableColumn: ColumnsType<INewSettlementById> = [
  {
    title: "이름",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },

  {
    title: "평점",
    dataIndex: "userScore",
    key: "userScore",
    ellipsis: true,
    align: "center",
    render: RenderUserScore,
  },

  {
    title: "나이",
    dataIndex: "birth",
    key: "birth",
    ellipsis: true,
    align: "center",
    render: (birth: string) => calculateAge(birth),
  },
  {
    title: "계좌번호",
    dataIndex: "bankAccount",
    key: "bankAccount",
    ellipsis: true,
  },
  {
    title: "은행명",
    dataIndex: "bankName",
    key: "bankName",
    ellipsis: true,
    align: "center",
  },
  {
    title: "예금주",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    align: "center",
  },
  {
    title: "공수",
    dataIndex: "manDay",
    key: "manDay",
    ellipsis: true,
    align: "center",
  },
  {
    title: "단가",
    dataIndex: "manDayAmount",
    key: "manDayAmount",
    ellipsis: true,
    align: "right",
    render: (manDayAmount: number) => `${manDayAmount?.toLocaleString()}원`,
  },
  {
    title: "입금액",
    dataIndex: "totalAmount",
    key: "totalAmount",
    ellipsis: true,
    align: "right",
    render: (totalAmount: number) => `${totalAmount?.toLocaleString()}원`,
  },
  {
    title: "입금확인시간",
    dataIndex: "paidAt",
    key: "paidAt",
    ellipsis: true,
  },
];
export { tableColumn };
