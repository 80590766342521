import { Card } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { IChatLog } from "apis/response";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { convertMessageTime } from "utils/utilDate";
import MDImage from "components/MDImage";

function ChatDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();

  const [chatLogs, setChatLogs] = useState<IChatLog[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [stopTrigger, setStopTrigger] = useState(false);

  useEffect(() => {
    Apis.getChatLogs(id, { page: currentPage, perPage: 15 })
      .then((resolve) => {
        setChatLogs((prevChatLogs) => [...prevChatLogs, ...resolve.data.data]);
        if (resolve.data.data.length === 0) {
          setStopTrigger(true);
        }
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, currentPage]);

  const loadMore = () => {
    if (chatLogs.length < total) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const listboxNode = event.currentTarget;
    const scrollPosition = listboxNode.scrollTop - listboxNode.clientHeight; // 현재 스크롤 위치
    const threshold = -(listboxNode.scrollHeight * 0.8); // 스크롤 높이의 80%

    if (scrollPosition <= threshold && loading === false && stopTrigger === false) {
      loadMore();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox
            p={2}
            style={{ height: "85vh", overflowY: "scroll" }}
            onScroll={handleOnScroll}
            gap={5}
            display={"flex"}
            flexDirection={"column-reverse"}
          >
            {/** Todo: 메시지 noti 타입일경우 처리 필요*/}
            {chatLogs &&
              chatLogs.map((chatLog, i) => (
                <MDBox
                  key={chatLog.messageId + i}
                  display={"flex"}
                  gap={4}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <MDImage
                    image={chatLog.profile}
                    style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  />
                  <MDBox>
                    <MDBox mb={2}>{chatLog.name}</MDBox>
                    <MDBox
                      border={1}
                      borderRadius="6px"
                      p={1}
                      style={{ boxShadow: "1px 1px 1px black" }}
                      maxWidth={"360px"}
                    >
                      {chatLog.message}
                    </MDBox>
                  </MDBox>
                  <MDBox height={"100%"} display={"flex"} alignItems={"end"}>
                    {convertMessageTime(chatLog.messageAt)}
                  </MDBox>
                </MDBox>
              ))}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ChatDetails;
