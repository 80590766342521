import { Card, Grid } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import { useLocation, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import Apis from "apis/remotes";
import { IWorkFieldEvaluation } from "apis/response";
import { paramsFilter } from "utils/ParamsFilter";

function WorkFieldEvaluationList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");
  const search = params.get("search");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [data, setData] = useState<IWorkFieldEvaluation[]>([]);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/labor-management/work-field-evaluation-list${paramsFilter({
        page,
        search,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/labor-management/work-field-evaluation-list${paramsFilter({
        search: text,
        page: 1,
      })}`
    );
  };

  useEffect(() => {
    Apis.getWorkFieldEvaluation({
      page: currentPage,
      perPage: 10,
      search,
    })
      .then((resolve) => {
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        {/* <Grid container py={3} spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Search
              maxLength={30}
              placeholder="공고명으로 검색하세요."
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
        </Grid> */}

        <Card>
          <Table
            // record.issue가 true인 로우는 테두리가 빨강으로 반짝반짝하게 해봐
            pagination={false}
            rowKey={(record) => record.workId}
            scroll={{ x: "max-content" }}
            dataSource={data}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/labor-management/work-field-evaluation-detail/${record.workId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default WorkFieldEvaluationList;
