import { ColumnsType } from "antd/es/table";
import { IUtilApproveDetails } from "apis/response";
import MDBox from "components/MDBox";

const tableColumn: ColumnsType<IUtilApproveDetails> = [
  {
    title: "승인 시간",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
    render: (title: string) => <MDBox style={{ maxWidth: 200 }}>{title}</MDBox>,
  },
  {
    title: "승인자 권한",
    dataIndex: "statusChanger",
    key: "statusChanger",

    ellipsis: true,
    render: (statusChanger: string) => {
      switch (statusChanger) {
        case "admin":
          return <MDBox style={{ maxWidth: 200 }}>관리자</MDBox>;
        default:
          return <MDBox style={{ maxWidth: 200 }}>인력사무소</MDBox>;
      }
    },
  },
];
export { tableColumn };
