import { Checkbox, Modal, Table, message } from "antd";
import Apis from "apis/remotes";
import { IOfferUser, IWorkById } from "apis/response";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Work from "./components/Work";
import MDButton from "components/MDButton";

import { tableColumn } from "./components/data";
import UserSearchForm from "./components/UserSearchForm";

function SpecialOffer(): JSX.Element {
  const { id } = useParams();

  const [announcement, setAnnouncement] = useState<IWorkById>();

  const [searchFilter, setSearchFilter] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [users, setUsers] = useState<IOfferUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IOfferUser[]>([]);

  const [showModal, setShowModal] = useState(false);

  const handleSubmitOffer = () => {
    const userIds = selectedUsers.map((user) => {
      return { userId: user.userId };
    });
    console.log(userIds);
    Apis.postOffer({
      workId: announcement.workId,
      title: announcement.title,
      data: userIds,
    })
      .then((res) => {
        message.success("성공적으로 특별 제안이 전송되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Apis.getWorkById(id)
      .then((res) => {
        setAnnouncement(res.data);
      })
      .catch((err) => {
        console.log(err.response.data.message ?? "");
      });
  }, [id]);

  useEffect(() => {
    if (!announcement) {
      return;
    }
    Apis.getOfferUser({
      workId: id,
      ...searchFilter,
      workDay: announcement?.workDay,
    })
      .then((res) => {
        setUsers(res.data.data);
        setTotal(res.data.total);
        setSelectedRowKeys([]);
        setSelectedUsers([]);
      })
      .catch((err) => {
        console.log(err.response.data.message ?? "");
      });
  }, [id, searchFilter, announcement]);

  const handleSelect = (record: IOfferUser, selected: boolean) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.userId]);
      setSelectedUsers((users) => [...users, record]);
    } else {
      setSelectedRowKeys((keys) => keys.filter((key) => key !== record.userId));
      setSelectedUsers((users) => users.filter((user) => user.userId !== record.userId));
    }
  };

  const toggleSelectAll = () => {
    if (selectedRowKeys.length === users.length) {
      setSelectedRowKeys([]);
      setSelectedUsers([]);
    } else {
      const allSelectedKeys = users.map((r) => r.userId);
      setSelectedRowKeys(allSelectedKeys);
      setSelectedUsers(users);
    }
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length === users.length && selectedRowKeys.length > 0}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < users.length}
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox" as const, // Explicitly set the type to "checkbox"
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Work announcement={announcement} />
      <UserSearchForm setFormData={setSearchFilter} />

      <Table
        rowSelection={rowSelection}
        pagination={{
          position: ["bottomLeft"],
          pageSize: 10,
          total: total,
          showSizeChanger: false,
        }}
        dataSource={users}
        rowKey={(record) => record.userId}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        footer={() => (
          <MDBox display={"flex"} justifyContent={"space-between"}>
            <MDBox>
              <MDButton variant="gradient" color="info" onClick={() => setShowModal(true)}>
                전송
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      />

      <Modal
        title="특별제안 미리보기"
        width={"60%"}
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => {
          handleSubmitOffer();
          setShowModal(false);
        }}
      >
        <Work announcement={announcement} />
        <Table
          pagination={{ pageSize: 10, showSizeChanger: false }}
          dataSource={selectedUsers}
          rowKey={(record) => record.userId}
          scroll={{ x: "max-content" }}
          columns={tableColumn}
        />
      </Modal>
    </DashboardLayout>
  );
}

export default SpecialOffer;
