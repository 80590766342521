import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { IEmploymentForm } from "apis/response";
import Apis from "apis/remotes";

import FormItem from "antd/es/form/FormItem";
import MDButton from "components/MDButton";
import Password from "antd/es/input/Password";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { handleInput } from "utils/PhoneString";

function NewEmployment(): JSX.Element {
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const postCreateWork = (formData: IEmploymentForm) => {
    // 폼 요소에서 employmentName, managerName, memo, email과 password 값을 가져오기
    setLoading(true);
    console.log(formData);
    Apis.postEmployment({ ...formData, role: "employ" })
      .then((resolve) => {
        message.success("인력사무소 등록이 완료되었습니다.");
        navigate("/member-management/employment-list");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={postCreateWork}
              autoComplete="off"
            >
              <FormItem
                label="인력사무소 업체명"
                name="employmentName"
                rules={[{ required: true, message: "업체명을 입력하세요." }]}
              >
                <Input type="text" />
              </FormItem>

              <FormItem
                label="인력사무소 담당자"
                name="managerName"
                rules={[{ required: true, message: "담당자명을 입력하세요." }]}
              >
                <Input type="text" />
              </FormItem>
              <FormItem
                label="인력사무소 연락처"
                name="phone"
                rules={[{ required: true, message: "연락처를 입력하세요." }]}
              >
                <Input type="tel" placeholder="01012345678" onInput={handleInput} maxLength={11} />
              </FormItem>
              <FormItem
                label="로그인 아이디"
                name="email"
                rules={[{ required: true, message: "아이디를 입력하세요." }]}
              >
                <Input type="text" />
              </FormItem>
              <FormItem
                label="비밀번호"
                name="password"
                rules={[{ required: true, message: "비밀번호를 입력하세요." }]}
              >
                <Password type="password" />
              </FormItem>
              <FormItem label="메모" name="memo">
                <Input.TextArea />
              </FormItem>
              <MDBox mt={4} mb={1}>
                <MDButton
                  disabled={loading}
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                >
                  등록
                </MDButton>
              </MDBox>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default NewEmployment;
