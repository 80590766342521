import { Card, Divider } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import ListItem from "./components/ListItem";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { IEvent } from "apis/response";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { message } from "antd";
import MDImage from "components/MDImage";

function EventDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [event, setEvent] = useState<IEvent>();

  /** 이벤트 삭제 버튼 클릭 */
  const handleDeleteEvent = () => {
    Apis.deleteEvent(id)
      .then((resolve) => {
        navigate("/notice-management/event-list");
        message.success("이벤트가 제거되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /** 이벤트 수정 버튼 클릭 */
  const handleNavigateUpdateEvent = () => {
    navigate(`/notice-management/update-event-details/${id}`);
  };

  useEffect(() => {
    Apis.getEventById(id)
      .then((resolve) => {
        setEvent(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <MDBox mb={2} lineHeight={1} display={"flex"} justifyContent={"space-between"}>
              <MDTypography variant="h3">{event?.title}</MDTypography>
              <MDButton color="info" size="small" onClick={() => handleNavigateUpdateEvent()}>
                이벤트 수정
              </MDButton>
            </MDBox>
            <MDBox opacity={0.3}>
              <Divider />
            </MDBox>
            <MDBox>
              <MDImage image={event?.image} alt="content_img" height={400} />
              <ListItem label={"본문내용"} values={event?.content} />
            </MDBox>
          </MDBox>
          <MDBox p={3} display={"flex"} justifyContent={"end"} gap={2}>
            <MDButton color="error" size="small" onClick={() => handleDeleteEvent()}>
              이벤트 삭제
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default EventDetails;
