import { ColumnsType } from "antd/es/table";
import { IBannerList } from "./dataType";
import { IBanners } from "apis/response";
import MDBadgeDot from "components/MDBadgeDot";
import MDImage from "components/MDImage";

const ADMIN_LIST: IBannerList[] = [
  {
    id: 1,
    name: "김민수",
    contact: "010-1234-5678",
    bannerName: "00현장",
    creationDate: new Date("2024-01-15"),
    views: 1500,
    status: "활성",
  },
  {
    id: 2,
    name: "이하늘",
    contact: "010-9876-5432",
    bannerName: "00현장",
    creationDate: new Date("2024-02-01"),
    views: 2300,
    status: "비활성",
  },
];
const tableColumn: ColumnsType<IBanners> = [
  {
    title: "썸네일",
    dataIndex: "thumbnail",
    key: "thumbnail",
    ellipsis: true,
    render: (thumbnail) => <MDImage height={50} image={thumbnail} alt="content_img" />,
  },
  {
    title: "내용",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
  },

  {
    title: "배너 상태",
    dataIndex: "isDisabled",
    key: "isDisabled",
    ellipsis: true,
    render: (isDisabled) =>
      isDisabled ? (
        <MDBadgeDot badgeContent="OFF" color="dark" />
      ) : (
        <MDBadgeDot badgeContent="ON" color="success" />
      ),
  },
];
export { ADMIN_LIST, tableColumn };
