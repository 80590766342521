/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Card } from "@mui/material";
import { useEffect } from "react";
import dayjs from "dayjs";

import Apis from "apis/remotes";

import { Form, Input, TimePicker, message } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import MDButton from "components/MDButton";
const disabledHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    if (i < 19 || i > 23) {
      hours.push(i);
    }
  }
  return hours;
};
// const disabledMinutes = (selectedHour: any) => {
//   const minutes = [];
//   if (selectedHour === 23) {
//     for (let i = 1; i < 60; i++) {
//       minutes.push(i);
//     }
//   }
//   return minutes;
// };
function Setting(): JSX.Element {
  const [form] = useForm();

  const putAdministratorSetting = (e: FormData & any) => {
    const { adminSettingId, autoApproveTime } = e;
    console.log(adminSettingId, dayjs(autoApproveTime).format("HH:mm:00"));
    Apis.putAdministratorAdminSettingById(adminSettingId, {
      autoApproveTime: dayjs(autoApproveTime).format("HH:mm:00"),
    })
      .then((resolve) => {
        message.success("success");
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  };
  useEffect(() => {
    Apis.getAdministratorAdminSetting()
      .then((resolve) => {
        console.log(resolve.data.data[0]);
        form.setFieldsValue({
          adminSettingId: resolve.data.data[0].adminSettingId,
          autoApproveTime: dayjs(resolve.data.data[0].autoApproveTime, "HH:mm:ss"),
        });
      })
      .catch((error) => {
        console.log(`${error.response.data.message ?? ""}`);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <Form form={form} onFinish={putAdministratorSetting}>
              <FormItem hidden label="adminSettingId" name={"adminSettingId"}>
                <Input type="text" />
              </FormItem>
              <FormItem
                name={"autoApproveTime"}
                extra="자동 승인 설정 시간은 19:00부터 23:59까지만 적용가능합니다."
                label="자동 승인 시간"
                rules={[{ required: true, message: "자동 승인 시간을 설정하세요." }]}
              >
                <TimePicker
                  format={"HH:mm"}
                  disabledHours={disabledHours}
                  // disabledMinutes={disabledMinutes}
                />
              </FormItem>
              <FormItem>
                <MDButton type="submit" color="info" variant="gradient">
                  변경
                </MDButton>
              </FormItem>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default Setting;
