import { Card, Divider, Form, Modal, Pagination, Table, message } from "antd";
import UserSearchForm from "../UserSearchForm";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { IOfferUser } from "apis/response";
import { tableColumn } from "./data";
import Apis from "apis/remotes";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface GiveCouponProps {
  couponId: string;
}

const GiveCoupon = ({ couponId }: GiveCouponProps) => {
  const [users, setUsers] = useState<IOfferUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IOfferUser[]>([]);

  const [searchFilter, setSearchFilter] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(Number(1));
  const [total, setTotal] = useState(0);

  const [form] = useForm();

  const [showModal, setShowModal] = useState(false);

  const handleSubmitCoupon = () => {
    const userIds = selectedUsers.filter((user) => {
      return { userId: user.userId };
    });

    Apis.postCouponDistribute({ couponId, data: userIds })
      .then((res) => {
        message.success("성공적으로 쿠폰이 지급되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Apis.getCouponUser({ ...searchFilter, page: currentPage, perPage: 10 })
      .then((res) => {
        setUsers(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err.response.data.message ?? "");
      });
  }, [searchFilter, currentPage]);

  return (
    <>
      <UserSearchForm
        setFormData={setSearchFilter}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Form form={form} onFinish={handleSubmitCoupon}>
        <Table
          rowSelection={{
            type: "checkbox",
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedUsers(selectedRows);
            },
          }}
          pagination={false}
          dataSource={users}
          rowKey={(record) => record.userId}
          scroll={{ x: "max-content" }}
          columns={tableColumn}
          style={{ cursor: "pointer" }}
          footer={() => (
            <MDBox display={"flex"} justifyContent={"space-between"}>
              <Pagination
                current={currentPage}
                onChange={(page) => {
                  setCurrentPage(page);
                }}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
              <MDBox>
                <MDButton variant="gradient" color="info" onClick={() => setShowModal(true)}>
                  지급
                </MDButton>
                <Modal
                  title={"쿠폰 지급 유저 목록"}
                  width={"60%"}
                  open={showModal}
                  onCancel={() => setShowModal(false)}
                  onOk={() => {
                    handleSubmitCoupon();
                    setShowModal(false);
                  }}
                  okText={"지급"}
                  cancelText={"취소"}
                >
                  <Table
                    pagination={false}
                    dataSource={selectedUsers}
                    rowKey={(record) => record.userId}
                    scroll={{ x: "max-content" }}
                    columns={tableColumn}
                    footer={() => (
                      <MDTypography
                        variant={"body2"}
                        color={"error"}
                        style={{ textAlign: "right" }}
                      >
                        * 정말로 지급하시겠습니까?
                      </MDTypography>
                    )}
                  />
                </Modal>
              </MDBox>
            </MDBox>
          )}
        />
      </Form>
    </>
  );
};

export default GiveCoupon;
