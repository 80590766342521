import { ColumnsType } from "antd/es/table";
import { IQnas } from "apis/response";
import MDBox from "components/MDBox";

const tableColumn: ColumnsType<IQnas> = [
  {
    title: "문의 요청",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    ellipsis: true,
    render: (content: string) => <MDBox style={{ maxWidth: 300 }}>{content}</MDBox>,
  },
  {
    title: "진행사항",
    dataIndex: "questionCommentId",
    key: "questionCommentId",
    ellipsis: true,
    render: (questionCommentId) => (questionCommentId ? "답변완료" : "진행중"),
  },
  {
    title: "문의 요청시간",
    dataIndex: "questionCreatedAt",
    key: "questionCreatedAt",
    ellipsis: true,
  },
];
export { tableColumn };
