import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

import { message } from "antd";
import Form from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import Input from "antd/es/input/Input";
import Password from "antd/es/input/Password";
import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { handleInput } from "utils/PhoneString";

function NewAdmin(): JSX.Element {
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCreateAdmin = (e: FormData) => {
    console.log(e);
    setLoading(true);
    Apis.postAdministrator({ ...e, role: "master" })
      .then((resolve) => {
        console.log(resolve);
        message.success("관리자 등록 성공");
        navigator("/admin-list");
      })
      .catch((error) => {
        console.log(error.response.data.message ?? "");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              onFinish={handleCreateAdmin}
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
            >
              <FormItem
                label="이름"
                name="nickName"
                rules={[
                  {
                    required: true,
                    message: "이름을 입력하세요.",
                  },
                ]}
              >
                <Input type="text" maxLength={20} showCount />
              </FormItem>
              <FormItem
                label="연락처"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "연락처를 01012345678과 같이 입력해주세요.",
                    // pattern: new RegExp("[0-9]{3}-[0-9]{4}-[0-9]{4}"),
                  },
                ]}
              >
                <Input type="text" onInput={handleInput} maxLength={11} />
              </FormItem>
              <FormItem
                label="아이디"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "아이디를 입력하세요.",
                  },
                ]}
              >
                <Input type="text" autoComplete="off" />
              </FormItem>
              <FormItem
                label="비밀번호"
                name="password"
                rules={[
                  { min: 6, message: "최소 6자리입니다." },
                  {
                    required: true,
                    message: "비밀번호를 입력하세요.",
                  },
                ]}
              >
                <Password
                  type="password"
                  visibilityToggle
                  autoComplete="new-password"
                  maxLength={10}
                  showCount
                />
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Link to={"/admin-list"}>
                    <MDButton fullWidth variant="contained" color="light">
                      취소
                    </MDButton>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    disabled={loading}
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    관리자 생성
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default NewAdmin;
