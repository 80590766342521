import { ColumnsType } from "antd/es/table";
import { DaysOfWeek, IUtils } from "apis/response";
import MDBox from "components/MDBox";

const daysOfWeek: Record<DaysOfWeek, string> = {
  Monday: "월요일",
  Tuesday: "화요일",
  Wednesday: "수요일",
  Thursday: "목요일",
  Friday: "금요일",
  Saturday: "토요일",
  Sunday: "일요일",
};

const tableColumn: ColumnsType<IUtils> = [
  {
    title: "지원일자",
    dataIndex: "date",
    key: "date",
    ellipsis: true,
    render: (title: string) => <MDBox style={{ maxWidth: 200 }}>{title}</MDBox>,
  },
  {
    title: "요일",
    dataIndex: "week",
    key: "week",
    ellipsis: true,
    render: (content: string) => (
      <MDBox style={{ maxWidth: 300 }}>{daysOfWeek[content as DaysOfWeek]}</MDBox>
    ),
  },
  {
    title: "평균 지원 시간",
    dataIndex: "averageTime",
    key: "averageTime",
    ellipsis: true,
  },
];

export { tableColumn };
