import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

import { Input, Upload, UploadFile, UploadProps, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";

import TextArea from "antd/es/input/TextArea";

import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import { Link, useNavigate } from "react-router-dom";
import { handleUploadCompMultiFiles, props } from "utils/Upload";

interface NoticeSubmitForm {
  title: string;
  content: string;
  image: any;
}

function NewBug(): JSX.Element {
  const [form] = useForm();
  const navigate = useNavigate(); // useNavigate 훅 추가
  const [loading, setLoading] = useState(false);
  const [siteThumbnail, setSiteThumbnail] = useState<UploadFile[]>([]);
  const handleCreateBug = async (data: NoticeSubmitForm) => {
    setLoading(true);
    const image = siteThumbnail[0]?.url.replace(process.env.REACT_APP_IMG_ENDPOINT, "") ?? "";
    console.log({ ...data, image });
    Apis.postDashBoardBug({ ...data, image })
      .then((resolve) => {
        message.success("오류 제보 완료");
        navigate("/bug-management/bug-list");
      })
      .catch((error) => {
        message.error(error);
      })
      .finally(() => setLoading(false));
  };
  const onChangeSiteThumbnailFile: UploadProps["onChange"] = async ({
    file,
    fileList: newFileList,
  }) => {
    if (file.status === "uploading") {
      // 업로드 중인 파일이 있으면 fileList만 업데이트하고 종료
      setSiteThumbnail(newFileList);
      return;
    }

    try {
      const image = await handleUploadCompMultiFiles("image", newFileList, "bug");

      // 파일 업로드 성공 후, 미리보기 이미지를 업데이트합니다.
      // 이때, presignedData.url은 서버에서 반환한 파일의 URL입니다.
      setSiteThumbnail([
        {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: image[0], // 서버에서 반환한 파일의 최종 URL
        },
      ]);
    } catch (error) {
      console.error("File upload failed:", error);
      message.error("파일 업로드에 실패했습니다.");
      // 업로드 실패 시, 현재 파일 리스트에서 해당 파일 제거
      setSiteThumbnail(newFileList.filter((f) => f.uid !== file.uid));
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={handleCreateBug}
              autoComplete="off"
            >
              <FormItem
                label="제목"
                name="title"
                rules={[{ required: true, message: "제목을 입력하세요" }]}
              >
                <Input maxLength={20} showCount />
              </FormItem>
              <FormItem
                label="본문 내용"
                name="content"
                rules={[{ required: true, message: "내용을 입력하세요" }]}
              >
                <TextArea maxLength={255} showCount />
              </FormItem>
              <FormItem label="캡쳐화면" name="image">
                <Upload.Dragger
                  style={{ width: "100%" }}
                  {...props}
                  multiple={false}
                  fileList={siteThumbnail}
                  onChange={onChangeSiteThumbnailFile}
                  accept="image/png, image/jpg, image/jpeg"
                  maxCount={1}
                >
                  <PlusOutlined />
                </Upload.Dragger>
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Link to={"/bug-management/bug-list"}>
                    <MDButton fullWidth variant="contained" color="light">
                      취소
                    </MDButton>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    disabled={loading}
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    오류 제보
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default NewBug;
