import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Checkbox, message, Table } from "antd";
import { useEffect, useState } from "react";
import Apis from "apis/remotes";
import { IGetUserOfferInsurance } from "apis/response";
import { ColumnsType } from "antd/es/table";
import { emptyString, genderString } from "utils";
import { inputToPhone } from "utils/PhoneString";
import dayjs from "dayjs";

interface Props {
  open: boolean;
  handleOpenState: Function;
}

function InsuranceTalkDialog({ open, handleOpenState }: Props): JSX.Element {
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [users, setUsers] = useState<IGetUserOfferInsurance[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IGetUserOfferInsurance[]>([]);

  const handleSubmit = () => {
    const userIds = selectedUsers.map((user) => {
      return { userId: user.userId };
    });
    console.log(userIds);
    Apis.postUserOfferInsurance({ data: userIds })
      .then((resolve) => {
        message.success("고용보험 알림톡이 전송되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        handleOpenState();
      });
  };

  useEffect(() => {
    Apis.getUserOfferInsurance()
      .then((resolve) => {
        setUsers(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const tableColumn: ColumnsType<IGetUserOfferInsurance> = [
    {
      title: "이름",
      dataIndex: "name",
      ellipsis: true,
      render: (name: string) => emptyString(name),
    },
    {
      title: "핸드폰번호",
      dataIndex: "phone",
      ellipsis: true,
      render: (phone: string) => inputToPhone(phone),
    },
    {
      title: "주소",
      dataIndex: "firstAddress",
      ellipsis: true,
      render: (firstAddress: string) => emptyString(firstAddress),
    },
    {
      title: "생년월일",
      dataIndex: "birth",
      ellipsis: true,
      render: (birth: string) => emptyString(birth),
    },
    {
      title: "성별",
      dataIndex: "gender",
      ellipsis: true,
      align: "center",
      render: (gender: string) => genderString(gender),
    },
    // {
    //   title: "직업",
    //   dataIndex: "occupation",
    //   ellipsis: true,
    //   render: (occupation: string) => emptyString(occupation),
    // },
    // {
    //   title: "직업 카테고리",
    //   dataIndex: "occupationCategory",
    //   ellipsis: true,
    //   render: (occupationCategory: string) => emptyString(occupationCategory),
    // },
    {
      title: "경력(년)",
      dataIndex: "career",
      ellipsis: true,
      align: "right",
      render: (career: string) => (career ? `${career}년` : "미입력"),
    },
    {
      title: "작업 횟수",
      dataIndex: "workCount",
      ellipsis: true,
      align: "right",
      render: (workCount: number) => workCount.toLocaleString(),
    },
    {
      title: "가입일자",
      dataIndex: "createdAt",
      ellipsis: true,
      render: (createdAt: string) => dayjs(createdAt).format("YYYY-MM-DD"),
    },
    // {
    //   title: "사용자 점수",
    //   dataIndex: "userScore",
    //   ellipsis: true,
    //   align: "right",
    //   render: (userScore: number) => userScore.toLocaleString(),
    // },
  ];

  const handleSelect = (record: IGetUserOfferInsurance, selected: boolean) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.userId]);
      setSelectedUsers((users) => [...users, record]);
    } else {
      setSelectedRowKeys((keys) => keys.filter((key) => key !== record.userId));
      setSelectedUsers((users) => users.filter((user) => user.userId !== record.userId));
    }
  };

  const toggleSelectAll = () => {
    if (selectedRowKeys.length === users.length) {
      setSelectedRowKeys([]);
      setSelectedUsers([]);
    } else {
      const allSelectedKeys = users.map((r) => r.userId);
      setSelectedRowKeys(allSelectedKeys);
      setSelectedUsers(users);
    }
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length === users.length && selectedRowKeys.length > 0}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < users.length}
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox" as const, // Explicitly set the type to "checkbox"
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  };
  return (
    <Dialog open={open} onClose={() => handleOpenState()} fullWidth maxWidth="xl">
      <DialogTitle>
        <MDBox display="flex" justifyContent="center">
          고용보험 미인증 유저 알림톡 전송하기
        </MDBox>
      </DialogTitle>
      <DialogContent>
        <MDBox mb={3}>
          <Table
            rowSelection={rowSelection}
            pagination={{
              position: ["bottomLeft"],
              pageSize: 10,
              total: total,
              // showSizeChanger: false,
            }}
            dataSource={users}
            rowKey={(record) => record.userId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // title={() => (
            //   <MDBox display={"flex"} justifyContent={"space-end"}>
            //     <MDBox>
            //       <MDButton onClick={handleSubmit} color="info">
            //         알림톡 전송
            //       </MDButton>
            //     </MDBox>
            //   </MDBox>
            // )}
            // footer={() => (
            //   <MDBox display={"flex"} justifyContent={"space-between"}>
            //     <MDBox>
            //       <MDButton onClick={handleSubmit} color="info">
            //         알림톡 전송
            //       </MDButton>
            //     </MDBox>
            //   </MDBox>
            // )}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => handleOpenState()}>취소</MDButton>
        <MDButton onClick={handleSubmit} color="info" disabled={selectedUsers.length === 0}>
          알림톡 전송
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default InsuranceTalkDialog;
