import { ColumnsType } from "antd/es/table";

import { ICoupon } from "apis/response";
import { Switch, message } from "antd";
import Apis from "apis/remotes";

const handleUpdateCouponEnabled = (couponId: number) => {
  Apis.putCouponById(couponId)
    .then((resolve) => {
      message.success("성공적으로 업데이트 되었습니다.");
      console.log(resolve);
    })
    .catch((error) => {
      console.log(error.response.data.message ?? "");
    });
};

const tableColumn: ColumnsType<ICoupon> = [
  {
    title: "쿠폰번호",
    dataIndex: "couponId",
    key: "couponId",
    ellipsis: true,
  },

  {
    title: "쿠폰명",
    dataIndex: "couponTitle",
    key: "couponTitle",
    ellipsis: true,
  },

  {
    title: "쿠폰설명",
    dataIndex: "couponContent",
    key: "couponContent",
    ellipsis: true,
  },

  {
    title: "코드",
    dataIndex: "code",
    key: "code",
    ellipsis: true,
  },

  {
    title: "쿠폰타입",
    dataIndex: "couponType",
    key: "couponType",
    ellipsis: true,
  },

  {
    title: "값",
    dataIndex: "value",
    key: "value",
    ellipsis: true,
    render: (value) => `${value.toLocaleString()}원`,
  },

  {
    title: "활성화",
    dataIndex: "enabled",
    key: "enabled",
    ellipsis: true,
    render: (_, record) => (
      <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        defaultChecked={record.enabled}
        onClick={(_, e: any) => {
          e.stopPropagation();
          handleUpdateCouponEnabled(record.couponId);
        }}
      />
    ),
  },
];
export { tableColumn };
