import { Grid } from "@mui/material";
import { Form } from "antd";
import FormItem from "antd/es/form/FormItem";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Input, InputNumber } from "antd";
import MDTypography from "components/MDTypography";

interface IUserSearchForm {
  setFormData: (data: any) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const UserSearchForm = ({ setFormData, currentPage, setCurrentPage }: IUserSearchForm) => {
  const handleOnSubmit = (form: any) => {
    const { ageStart, ageEnd } = form;
    const currentYear = new Date().getFullYear();
    const convertAgeStart = currentYear - ageStart;
    const convertAgeEnd = currentYear - ageEnd;
    setCurrentPage(1);
    setFormData({
      ...form,
      page: currentPage,
      perPage: 10,
      ageStart: convertAgeEnd,
      ageEnd: convertAgeStart,
    });
  };

  return (
    <Form onFinish={handleOnSubmit} style={{ width: "100%" }}>
      <Grid container py={2} spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MDBox display="flex" alignItems="center" gap={1}>
            <FormItem>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                나이
              </MDTypography>
            </FormItem>
            <FormItem
              name="ageStart"
              rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
              initialValue={18}
            >
              <InputNumber min={18} max={99} maxLength={2} style={{ width: "60px" }} />
            </FormItem>
            <FormItem
              name="ageEnd"
              rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
              initialValue={60}
            >
              <InputNumber min={18} max={99} maxLength={2} style={{ width: "60px" }} />
            </FormItem>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MDBox display="flex" alignItems="center" gap={1}>
            <FormItem>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                평점
              </MDTypography>
            </FormItem>
            <FormItem
              name="userScore"
              rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
              initialValue={0}
            >
              <InputNumber min={0} max={5} maxLength={1} style={{ width: "70px" }} />
            </FormItem>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MDBox display="flex" alignItems="center" gap={1}>
            <FormItem>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                근로 횟수
              </MDTypography>
            </FormItem>
            <FormItem
              name="workCount"
              rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
              initialValue={0}
            >
              <InputNumber
                min={0}
                max={5}
                maxLength={1}
                style={{ width: "70px" }}
                defaultValue={0}
              />
            </FormItem>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <MDBox display="flex" gap={1}>
            <FormItem>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                이름
              </MDTypography>
            </FormItem>
            <FormItem name={"search"}>
              <Input
                type="text"
                placeholder="이름, 전화번호, 이메일로 검색하세요."
                style={{ width: "150%" }}
                maxLength={20}
                showCount
              />
            </FormItem>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} gridRow={-1} gridColumn={-1}>
          <MDBox display="flex" justifyContent={"end"} marginRight={"16px"}>
            <MDButton color="info" size="small" type="submit" style={{ width: "32%" }}>
              검색
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </Form>
  );
};

export default UserSearchForm;
