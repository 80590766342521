import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

import { Input, InputNumber, Select, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";

import TextArea from "antd/es/input/TextArea";

import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

interface CouponSubmitForm {
  couponName: string;
  code: string;
  couponType: string;
  value: number;
}

function CreateCoupon(): JSX.Element {
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCreateCoupon = async (couponForm: CouponSubmitForm) => {
    if (couponForm.value % 5000 !== 0) {
      message.error("쿠폰 가격은 5000원 단위로 입력해주세요.");
      return;
    }
    setLoading(true);
    Apis.postCoupon(couponForm)
      .then((resolve) => {
        message.success("쿠폰이 등록되었습니다.");
        navigate("/coupon-management/coupon-list");
      })
      .catch((error) => {
        console.log(error.response.data.message ?? "");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={handleCreateCoupon}
              autoComplete="off"
            >
              <FormItem
                label="쿠폰 이름"
                name="couponTitle"
                rules={[{ required: true, message: "쿠폰 이름을 입력하세요" }]}
              >
                <Input
                  type="text"
                  placeholder="쿠폰 이름을 입력하세요"
                  style={{ maxWidth: 400 }}
                  maxLength={20}
                  showCount
                />
              </FormItem>
              <FormItem
                label="쿠폰 설명"
                name="couponContent"
                rules={[{ required: true, message: "쿠폰 설명을 입력하세요" }]}
              >
                <Input
                  type="text"
                  placeholder="쿠폰 설명을 입력하세요"
                  style={{ maxWidth: 400 }}
                  maxLength={20}
                  showCount
                />
              </FormItem>
              <FormItem
                label="코드"
                name="code"
                extra="쿠폰 코드는 영어, 숫자, 특수문자만 입력 가능합니다."
                rules={[
                  { required: true, message: "쿠폰 코드를 입력하세요" },
                  {
                    pattern: /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/,
                    message: "영어, 숫자, 특수문자만 입력 가능합니다.",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="쿠폰 코드를 입력하세요(영어, 숫자, 특수문자)"
                  style={{ maxWidth: 400 }}
                  maxLength={20}
                  showCount
                />
              </FormItem>
              <FormItem label="쿠폰 타입" name="couponType">
                <Select defaultValue={"bonus"} style={{ width: 120 }}>
                  <Select.Option value="bonus">보너스</Select.Option>
                </Select>
              </FormItem>
              <FormItem
                label="쿠폰 가격"
                name="value"
                rules={[{ required: true, message: "쿠폰 가격을 입력하세요" }]}
              >
                <InputNumber
                  formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                  style={{ minWidth: "150px" }}
                  maxLength={7}
                  min={5000}
                  max={100000}
                  step={5000}
                  changeOnWheel
                />
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <MDButton
                    LinkComponent={Link}
                    to="/coupon-management/coupon-list"
                    fullWidth
                    variant="contained"
                    color="light"
                  >
                    취소
                  </MDButton>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    disabled={loading}
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    등록
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default CreateCoupon;
