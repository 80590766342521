function genderString(gender: string) {
  switch (gender) {
    case "male":
      return "남성";
    case "female":
      return "여성";
    default:
      return "미입력";
  }
}
const emptyString = (text: string) => {
  return text === "" ? "미입력" : text;
};
export { genderString, emptyString };
