import { Table, message } from "antd";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import DetailApplicantDialog from "./components/DetailApplicantDialog";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import Apis from "apis/remotes";
import { IMatchApply } from "apis/response";
import MDButton from "components/MDButton";

import { calculateAge, checkAbsenceDisable, checkDepartureDisable } from "utils/utilDate";
import { Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import RenderUserScore from "components/RenderUserScore";
import { inputToPhone } from "utils/PhoneString";
import { Link } from "react-router-dom";
import MDBadge from "components/MDBadge";
interface Props {
  workId: string | number;
  handleUpdateAnnouncementStatus: Function;
}
/** 퇴근확인 컴포넌트 */
function DepartureCheck({ workId, handleUpdateAnnouncementStatus }: Props): JSX.Element {
  console.log("DepartureCheck show");
  const [applicants, setApplicants] = useState<IMatchApply[]>([]);
  // const [selectedApplicant, setSelectedApplicant] = useState<IGetUserById>();
  // const [open, setOpen] = useState(false);

  // const handleOpenState = () => setOpen((prev) => !prev);
  // const handleShowApplicantDetailDialog = (id: number) => {
  //   Apis.getUserById(id)
  //     .then((resolve) => {
  //       console.log(resolve.data);
  //       setSelectedApplicant(resolve.data);
  //       setOpen(true);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  /** 퇴근확인 버튼 클릭 */
  const handleUpdateMatchOffWork = (applicantId: string | number) => {
    Apis.putMatchOffWork(applicantId)
      .then((resolve) => {
        handleUpdateAnnouncementStatus();
        setApplicants((prev) =>
          prev.map((applicant) => {
            if (applicant.userApplicantId === applicantId) {
              return { ...applicant, status: "퇴근확인" };
            } else {
              return applicant;
            }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /** 퇴근 확인 알림 보내기  */
  const handlePostMatchOffWorkFcm = ({ workId, userId }: { workId: number; userId: number }) => {
    Apis.postMatchOffWorkFcm({ workId, userId })
      .then((resolve) => {
        message.success("퇴근 알림 메시지가 전송되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /** 결근 처리 버튼 클릭 */
  const handleUpdateMatchAbsence = (applicantId: string | number) => {
    Apis.putMatchAbsenceById(applicantId)
      .then((resolve) => {
        handleUpdateAnnouncementStatus();
        setApplicants((prev) =>
          prev.map((applicant) => {
            if (applicant.userApplicantId === applicantId) {
              return { ...applicant, status: "결근" };
            } else {
              return applicant;
            }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    Apis.getMatchCurrentApply(workId, { status: "offWork" })
      .then((resolve) => {
        setApplicants(resolve.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [workId]);

  const tableColumn: ColumnsType<IMatchApply> = [
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "center",
      render: (name: string, record) => (
        <MDBox
          textAlign={"center"}
          component={Link}
          to={`/member-management/member-details/${record.userId}`}
        >
          {record.isDeleted ? (
            <p style={{ color: "red", fontSize: 12 }}>탈퇴회원</p>
          ) : record.isPartner === true ? (
            <p style={{ color: "orange", fontSize: 12 }}>파트너</p>
          ) : null}
          {/* {record.isPartner === true ? (
            <p style={{ color: "orange", fontSize: 12 }}>파트너</p>
          ) : null} */}
          <span>{name}</span>
        </MDBox>
      ),
    },
    {
      title: "고용보험",
      dataIndex: "insuranceDay",
      key: "insuranceDay",
      align: "center",
      render: (insuranceDay: number) => {
        if (insuranceDay === 0) {
          return <MDBadge badgeContent="미인증" container size="sm" color="error" />;
        } else {
          return <MDBadge badgeContent="인증" container size="sm" color="success" />;
        }
      },
    },
    {
      title: "평점",
      dataIndex: "userScore",
      key: "userScore",
      render: RenderUserScore,
    },

    {
      title: "나이",
      dataIndex: "birth",
      key: "birth",
      render: (birth: string) => calculateAge(birth),
    },
    {
      title: "연락처",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      render: (phone: string) => inputToPhone(phone),
    },
    {
      title: (
        <p>
          해당 현장 <br />
          출근 수
        </p>
      ),
      dataIndex: "targetWorkCount",
      key: "targetWorkCount",
      align: "center",
      render: (targetWorkCount: number) =>
        targetWorkCount > 0 ? (
          `${targetWorkCount}회 출근`
        ) : (
          <Typography color="error" variant="h6">
            최초 출근
          </Typography>
        ),
    },
    {
      title: (
        <p>
          최근 10회 <br />
          근무 결근 수
        </p>
      ),
      dataIndex: "recentAbsenceCount",
      key: "recentAbsenceCount",
      align: "center",
      render: (recentAbsenceCount: number) => {
        switch (recentAbsenceCount) {
          case 0:
            return "-";
          case 1:
            return 1;
          case 2:
            return 2;
          default:
            return (
              <Typography color="error" variant="h6">
                {recentAbsenceCount}
              </Typography>
            );
        }
      },
    },
    {
      title: "혈압",
      dataIndex: "bloodPressure",
      key: "bloodPressure",
      align: "center",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      ellipsis: true,
      render: (_, record) => (
        <MDBox display={"flex"} gap={2} alignItems={"center"}>
          <MDButton
            size="small"
            color="info"
            disabled={record.status === "퇴근확인" || checkDepartureDisable()}
            onClick={(e) => {
              handleUpdateMatchOffWork(record.userApplicantId);
            }}
          >
            {record.status === "퇴근확인" ? "퇴근 확인 완료" : "퇴근 확인"}
          </MDButton>
          <MDButton
            size="small"
            color="info"
            onClick={(e) => {
              handlePostMatchOffWorkFcm({ workId: record.workId, userId: record.userId });
            }}
          >
            알림 보내기
          </MDButton>
          <MDButton
            size="small"
            color="error"
            disabled={
              record.status === "결근" || record.status === "퇴근확인" || checkAbsenceDisable()
            }
            onClick={() => handleUpdateMatchAbsence(record.userApplicantId)}
          >
            결근 처리
          </MDButton>
        </MDBox>
      ),
    },
  ];
  return (
    <MDBox>
      <MDTypography variant="h5">퇴근확인</MDTypography>
      <MDBox mt={3}>
        <Table
          // showHeader={false}
          pagination={false}
          rowKey={(record) => record.userApplicantId}
          columns={tableColumn}
          dataSource={applicants}
          scroll={{ x: "max-content" }}
        />
      </MDBox>
      {/* <DetailApplicantDialog
        open={open}
        handleOpenState={handleOpenState}
        selectedApplicant={selectedApplicant}
      /> */}
    </MDBox>
  );
}
export default DepartureCheck;
