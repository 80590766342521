import { Card } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { IInsuranceStaticsById } from "apis/response";
import { Pagination, PaginationProps, Table, TableColumnsType } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
import { inputToPhone } from "utils/PhoneString";

function InsuranceStatisticsDetails(): JSX.Element {
  const { id } = useParams();
  const [user, setUser] = useState<IInsuranceStaticsById[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    console.log("id", id);
    console.log("currentPage", currentPage);
    Apis.getInsuranceStaticsById(id, { page: currentPage, perPage: 10 })
      .then((resolve) => {
        setUser(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, currentPage]);

  const columns: TableColumnsType<IInsuranceStaticsById> = [
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "연락처",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (phone: string) => inputToPhone(phone),
    },
    {
      title: "고용보험 인증 날짜",
      dataIndex: "insuranceUserDay",
      key: "insuranceUserDay",
    },
  ];

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Table
          dataSource={user}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.userId}
          footer={() => (
            <Pagination
              current={currentPage}
              onChange={handleOnChangePage}
              pageSize={10}
              total={total}
              showSizeChanger={false}
            />
          )}
        />
      </Card>
    </DashboardLayout>
  );
}

export default InsuranceStatisticsDetails;
