import { Card } from "@mui/material";
import { Pagination, PaginationProps, Table, Image, message } from "antd";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { paramsFilter } from "utils/ParamsFilter";

import { IBugs } from "apis/response";
import Apis from "apis/remotes";
import { ColumnsType } from "antd/es/table";
import MDButton from "components/MDButton";
import MDBadgeDot from "components/MDBadgeDot";
import MDBox from "components/MDBox";

function BugListAlpha(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [bugList, setBugList] = useState<IBugs[]>([]);
  const [total, setTotal] = useState(0);
  const tableColumn: ColumnsType<IBugs> = [
    {
      title: "adminBugId",
      dataIndex: "adminBugId",
      key: "adminBugId",
      ellipsis: true,
    },
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      render: (title: string) => <MDBox style={{ maxWidth: 200 }}>{title}</MDBox>,
    },
    {
      title: "제보 내용",
      dataIndex: "content",
      key: "content",
      ellipsis: true,
      render: (content: string) => <MDBox style={{ maxWidth: 300 }}>{content}</MDBox>,
    },
    {
      title: "캡쳐화면",
      dataIndex: "image",
      key: "image",
      ellipsis: true,
      render: (image: string) => {
        if (!image.includes("images/origin")) {
          return "캡쳐화면 없음.";
        } else {
          return (
            <Image width={100} preview src={`${process.env.REACT_APP_IMG_ENDPOINT}` + image} />
          );
        }
      },
    },
    {
      title: "제보자 유형",
      dataIndex: "reporterType",
      key: "reporterType",
      ellipsis: true,
    },
    {
      title: "진행상황",
      dataIndex: "status",
      key: "status",
      ellipsis: true,

      render: (status: "에러 해결" | "에러 접수" | "보류", record) => {
        switch (status) {
          case "에러 접수":
            return (
              <MDButton
                size="small"
                color="info"
                variant="gradient"
                onClick={() => handlePutDashBoardAlpha(record.adminBugId)}
              >
                수정 완료
              </MDButton>
            );
          case "에러 해결":
            return (
              <MDBadgeDot badgeContent="에러 해결" size="sm" color="success" variant="gradient" />
            );
          case "보류":
            return <MDBadgeDot badgeContent="보류" size="sm" color="light" variant="gradient" />;
          default:
            return <MDBadgeDot badgeContent={status} size="sm" color="light" variant="gradient" />;
        }
      },
    },
  ];
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/bug-management/bug-list-alpha${paramsFilter({
        page,
      })}`
    );
    setCurrentPage(page);
  };

  const handlePutDashBoardAlpha = (id: string | number) => {
    Apis.putDashBoardAlphaById(id)
      .then((resolve) => {
        setBugList((prev) =>
          prev.map((item) => {
            if (item.adminBugId === id) {
              return { ...item, status: "에러 해결" };
            } else {
              return item;
            }
          })
        );
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  };

  useEffect(() => {
    Apis.getDashBoardAlpha({ page: currentPage, perPage: 5 })
      .then((resolve) => {
        setBugList(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <Table
          pagination={false}
          rowKey={(record) => record.adminBugId}
          scroll={{ x: "max-content" }}
          dataSource={bugList}
          columns={tableColumn}
          footer={() => (
            <Pagination
              current={currentPage}
              onChange={handleOnChangePage}
              pageSize={5}
              total={total}
              showSizeChanger={false}
            />
          )}
        />
      </Card>
    </DashboardLayout>
  );
}
export default BugListAlpha;
