import { ColumnsType } from "antd/es/table";
import { IChatNotice } from "apis/response";
import MDBox from "components/MDBox";

const tableColumn: ColumnsType<IChatNotice> = [
  {
    title: "수신자",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      if (record.sendAll) {
        return "전체";
      }
      return record.name;
    },
    ellipsis: true,
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    ellipsis: true,
    render: (content: string) => <MDBox style={{ maxWidth: 300 }}>{content}</MDBox>,
  },
  {
    title: "공지 시간",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
  },
];

export { tableColumn };
