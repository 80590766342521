import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

import { Upload, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";

import TextArea from "antd/es/input/TextArea";

import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

interface NoticeSubmitForm {
  content: string;
}

function CreateChatNotice(): JSX.Element {
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const handleCreateNotice = async (noticeSubmitForm: NoticeSubmitForm) => {
    setLoading(true);
    Apis.postChatNotice(noticeSubmitForm)
      .then((resolve) => {
        message.success("공지가 등록되었습니다.");
        navigate("/chat-management/chat-notice-list");
      })
      .catch((error) => {
        console.log(error.response.data.message ?? "");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={handleCreateNotice}
              autoComplete="off"
            >
              <FormItem
                label="공지 내용"
                name="content"
                rules={[{ required: true, message: "내용을 입력하세요" }]}
              >
                <TextArea maxLength={255} showCount />
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <MDButton
                    LinkComponent={Link}
                    to="/chat-management/chat-notice-list"
                    fullWidth
                    variant="contained"
                    color="light"
                  >
                    취소
                  </MDButton>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    disabled={loading}
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    등록
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default CreateChatNotice;
