import { Card } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { Pagination, PaginationProps, Table } from "antd";

import { tableColumn } from "./components/data";
import { IUtilApproveDetails, IUtilDetails } from "apis/response";

function WorkApproveTimeDetails() {
  const { id } = useParams();
  const [utils, setUtils] = useState<IUtilApproveDetails[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    Apis.getUtilApproveDetails({ date: id, page: currentPage, perpage: 10 })
      .then((resolve) => {
        setUtils(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, currentPage]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.createdAt}
            scroll={{ x: "max-content" }}
            dataSource={utils}
            columns={tableColumn}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default WorkApproveTimeDetails;
