import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";

import { Upload, UploadFile, UploadProps, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";

import TextArea from "antd/es/input/TextArea";

import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { handleUploadCompMultiFiles, props, startEdit } from "utils/Upload";
interface BannerSubmtForm {
  thumbnail: any;
  image: any;
  description: string;
}
function UpdateBanner(): JSX.Element {
  const { id } = useParams();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState<UploadFile[]>([]);
  const [image, setImage] = useState<UploadFile[]>([]);

  const handleUpdateBanner = async (bannerForm: BannerSubmtForm) => {
    try {
      setLoading(true);
      const images = (await handleUploadCompMultiFiles("image", image, "banner")) ?? [];
      const thumbnails = (await handleUploadCompMultiFiles("image", thumbnail, "banner")) ?? [];

      Apis.putBanner(id, {
        description: bannerForm.description,
        image: images[0],
        thumbnail: thumbnails[0],
      })
        .then((resolve) => {
          message.success("배너가 수정되었습니다.");
          navigate(`/member-management/banner-details/${id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeThumbnail: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList;
    form.setFieldValue("thumbnail", newupdatedFileList[0]);
    setThumbnail(newupdatedFileList);
  };
  const onChangeImage: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList;
    form.setFieldValue("image", newupdatedFileList[0]);
    setImage(newupdatedFileList);
  };

  useEffect(() => {
    Apis.getBannerById(id)
      .then((resolve) => {
        const imageData = resolve.data;
        const prevImage: UploadFile = {
          uid: `done file image`,
          name: imageData.image,
          status: "done",
          url: `${process.env.REACT_APP_IMG_ENDPOINT}${imageData.image}`,
        };
        const prevThumbnail: UploadFile = {
          uid: `done file image`,
          name: imageData.thumbnail,
          status: "done",
          url: `${process.env.REACT_APP_IMG_ENDPOINT}${imageData.thumbnail}`,
        };
        form.setFieldsValue({
          description: imageData.description,
          image: [prevImage],
          thumbnail: [prevThumbnail],
        });

        startEdit([imageData.image], setImage);
        startEdit([imageData.thumbnail], setThumbnail);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Typography variant="h4">배너 수정</Typography>
          </MDBox>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={handleUpdateBanner}
              autoComplete="off"
            >
              <FormItem
                label="배너 썸네일"
                name="thumbnail"
                rules={[{ required: true, message: "배너 썸네일을 업로드하세요" }]}
              >
                <Upload.Dragger
                  onChange={onChangeThumbnail}
                  fileList={thumbnail}
                  {...props}
                  accept="image/png,image/jpg,image/jpeg"
                />
              </FormItem>
              <FormItem
                label="배너 이미지"
                name="image"
                rules={[{ required: true, message: "배너 이미지를 업로드하세요" }]}
              >
                <Upload.Dragger
                  onChange={onChangeImage}
                  fileList={image}
                  {...props}
                  accept="image/png,image/jpg,image/jpeg"
                />
              </FormItem>
              <FormItem
                label="본문 내용"
                name="description"
                rules={[{ required: true, message: "내용을 입력하세요" }]}
              >
                <TextArea maxLength={255} showCount />
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Link to={`/member-management/banner-details/${id}`}>
                    <MDButton fullWidth variant="contained" color="light">
                      취소
                    </MDButton>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    disabled={loading}
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    수정
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default UpdateBanner;
