import { ColumnsType } from "antd/es/table";
import { IEvent } from "apis/response";

const tableColumn: ColumnsType<IEvent> = [
  {
    title: "제목",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    ellipsis: true,
  },
];
export { tableColumn };
