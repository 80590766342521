import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Checkbox, Divider, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { IWorkFieldEvaluationWorker } from "apis/response";
import { useParams } from "react-router-dom";
import { calculateAge } from "utils/utilDate";
import { genderString } from "utils";
import { inputToPhone } from "utils/PhoneString";
import MDTypography from "components/MDTypography";
import FormItem from "antd/es/form/FormItem";
import Apis from "apis/remotes";

const { Option } = Select;

function WorkFieldEvaluationDetails(): JSX.Element {
  const { id } = useParams();
  const [userList, setUserList] = useState<IWorkFieldEvaluationWorker[]>([]);
  // const [form] = Form.useForm();

  useEffect(() => {
    Apis.getWorkFieldEvaluationById(id)
      .then((resolve) => {
        setUserList(resolve.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // // 아직 API가 준비되지 않아서, 가짜 데이터를 사용함
    // setUserList(fakeUserData);

    // // initialValues 객체에 대한 타입 명시
    // const initialValues: { [key: string]: any } = {};
    // fakeUserData.forEach((user) => {
    //   initialValues[`rating_${user.userId.toString()}`] = user.workEvaluationScore;
    //   initialValues[`replacement_${user.userId.toString()}`] = user.workEvaluationReplacement;
    //   initialValues[`comments_${user.userId.toString()}`] = user.workEvaluationComment;
    // });
    // form.setFieldsValue(initialValues);
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <Form layout="vertical">
              {userList.map((user) => (
                <MDBox key={user.userId} mb={3}>
                  <MDBox display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <MDBox>
                      <MDTypography variant="h6" fontWeight="bold">
                        {user.name}
                      </MDTypography>
                      <MDTypography variant="body">
                        {genderString(user.gender)} | {calculateAge(user.birth)}
                      </MDTypography>
                      <MDTypography variant="body2" color="textSecondary">
                        {inputToPhone(user.phone)}
                      </MDTypography>
                    </MDBox>
                    <MDTypography
                      variant="body2"
                      color={user.status === "결근" ? "error" : "success"}
                      fontWeight="medium"
                    >
                      {user.status}
                    </MDTypography>
                  </MDBox>

                  <FormItem label="평점 (1~5점)" name={`rating_${user.userId.toString()}`}>
                    <Select
                      placeholder="점수를 선택하세요"
                      style={{ pointerEvents: "none" }}
                      defaultValue={user.workEvaluationScore}
                    >
                      <Option value={1}>1점</Option>
                      <Option value={2}>2점</Option>
                      <Option value={3}>3점</Option>
                      <Option value={4}>4점</Option>
                      <Option value={5}>5점</Option>
                    </Select>
                  </FormItem>

                  <FormItem name={`replacement_${user.userId.toString()}`} valuePropName="checked">
                    <Checkbox
                      defaultChecked={user.workEvaluationReplacement}
                      style={{ pointerEvents: "none" }}
                    >
                      신규인력 대체 요청
                    </Checkbox>
                  </FormItem>

                  <FormItem label="특이사항" name={`comments_${user.userId.toString()}`}>
                    <Input.TextArea
                      defaultValue={user.workEvaluationComment}
                      placeholder="특이사항이 없다면 비워두세요."
                      maxLength={255}
                      rows={4}
                      readOnly
                    />
                  </FormItem>

                  <Divider />
                </MDBox>
              ))}
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default WorkFieldEvaluationDetails;
