import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

import { Upload, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";

import TextArea from "antd/es/input/TextArea";

import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { handleUploadCompMultiFiles, props } from "utils/Upload";
interface BannerSubmtForm {
  thumbnail: any;
  image: any;
  description: string;
}
function NewBanner(): JSX.Element {
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleCreateBanner = async (bannerForm: BannerSubmtForm) => {
    // console.log(bannerForm.thumbnail.file.type);
    // console.log(bannerForm.image.file.type);

    try {
      setLoading(true);
      console.log(bannerForm.thumbnail.file);
      console.log(bannerForm.image.file);
      const image =
        (await handleUploadCompMultiFiles("image", [bannerForm.image.file], "banner")) ?? [];
      const thumbnail =
        (await handleUploadCompMultiFiles("image", [bannerForm.thumbnail.file], "banner")) ?? [];
      console.log(image);
      console.log(thumbnail);
      Apis.postBanner({
        ...bannerForm,
        image: image[0],
        thumbnail: thumbnail[0],
      })
        .then((resolve) => {
          message.success("배너가 등록되었습니다.");
          navigate("/member-management/banner-list");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={handleCreateBanner}
              autoComplete="off"
            >
              <FormItem
                label="배너 썸네일"
                name="thumbnail"
                rules={[{ required: true, message: "배너 썸네일을 업로드하세요" }]}
              >
                <Upload.Dragger
                  {...props}
                  multiple={false}
                  accept="image/png,image/jpg,image/jpeg"
                  maxCount={1}
                />
              </FormItem>
              <FormItem
                label="배너 이미지"
                name="image"
                rules={[{ required: true, message: "배너 이미지를 업로드하세요" }]}
              >
                <Upload.Dragger
                  {...props}
                  multiple={false}
                  accept="image/png,image/jpg,image/jpeg"
                  maxCount={1}
                />
              </FormItem>
              <FormItem
                label="본문 내용"
                name="description"
                rules={[{ required: true, message: "이름을 입력하세요" }]}
              >
                <TextArea maxLength={255} showCount />
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Link to={"/member-management/banner-list"}>
                    <MDButton fullWidth variant="contained" color="light">
                      취소
                    </MDButton>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    disabled={loading}
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    등록
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default NewBanner;
