import { Card, Divider } from "@mui/material";
import Apis from "apis/remotes";
import { IEmploymentsById } from "apis/response";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ListItem from "./components/ListItem";
import MDButton from "components/MDButton";
import { message } from "antd";
import { inputToPhone } from "utils/PhoneString";

function EmploymentDetails(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employment, setEmployment] = useState<IEmploymentsById>();
  const [loading, setLoading] = useState(false);
  const handleDeleteEmployment = () => {
    setLoading(true);
    Apis.deleteEmployment(id)
      .then((resolve) => {
        message.warning("인력사무소 계정이 삭제되었습니다.");
        navigate("/member-management/employment-list");
      })
      .catch((error) => {
        console.log(error.response.data.message ?? "");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    Apis.getEmploymentById(id)
      .then((resolve) => {
        setEmployment(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox p={2} display={"flex"} justifyContent={"end"}>
          <MDButton
            color="warning"
            LinkComponent={Link}
            to={`/member-management/update-employment/${id}`}
          >
            정보 수정
          </MDButton>
        </MDBox>
        <Card>
          <MDBox p={2}>
            <MDBox>
              <ListItem label={"인력사무소명"} values={employment?.employmentName} />
              <ListItem label={"담당자"} values={employment?.employmentName} />
              <ListItem label={"담당자 연락처"} values={inputToPhone(employment?.phone ?? "")} />
            </MDBox>
          </MDBox>
          <MDBox opacity={0.3}>
            <Divider />
          </MDBox>
          <MDBox p={2}>
            <ListItem label={"아이디"} values={employment?.email} />
            <ListItem label={"메모"} values={employment?.memo} />
          </MDBox>
        </Card>
        <MDBox p={2} display={"flex"} justifyContent={"end"}>
          <MDButton color="error" onClick={() => handleDeleteEmployment()}>
            인력사무소 삭제
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default EmploymentDetails;
