import { Grid } from "@mui/material";
import { Form, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import Apis from "apis/remotes";
import { useForm } from "antd/es/form/Form";

interface IUserSearchForm {
  setFormData: (data: any) => void;
  // currentPage: number;
  // setCurrentPage: (page: number) => void;
}

type OptionType = {
  value: string;
  label: string;
};

function UserSearchForm({
  setFormData,
}: // currentPage,
// setCurrentPage,
IUserSearchForm): JSX.Element {
  /** 지역 필터링 */
  const [cityList, setCityList] = useState<OptionType[]>([]);
  const [districtList, setDistrictList] = useState<OptionType[]>([]);

  const [currentCity, setCurrentCity] = useState<string | null>(null);
  const [currentDistrict, setCurrentDistrict] = useState<string | null>(null);

  const [form] = useForm();

  const handleOnSubmit = (form: any) => {
    const { ageStart, ageEnd } = form;
    const currentYear = new Date().getFullYear();

    let formData = {
      ...form,
      // page: currentPage,
      perPage: 10,
    };

    if (ageStart !== undefined || ageEnd !== undefined) {
      const convertAgeStart = ageStart !== undefined ? currentYear - ageStart : undefined;
      const convertAgeEnd = ageEnd !== undefined ? currentYear - ageEnd : undefined;
      formData = {
        ...formData,
        ageStart: convertAgeEnd,
        ageEnd: convertAgeStart,
      };
    }
    console.log(formData);
    // setCurrentPage(1);
    setFormData(formData);
  };

  useEffect(() => {
    Apis.getCodeListCity().then((resolve) => {
      const cityList = resolve.data.data.map((item) => {
        return { value: item.city, label: item.city };
      });
      setCityList(cityList);
    });
  }, []);

  useEffect(() => {
    if (currentCity) {
      Apis.getCodeListCityDistrict(currentCity).then((resolve) => {
        const districtList = resolve.data.data.map((item) => {
          return { value: item.district, label: item.district };
        });
        setDistrictList(districtList);
      });
    } else {
      setDistrictList([]);
    }
  }, [currentCity]);

  const handleCityFilter = (value: any) => {
    setCurrentCity(value);
    console.log("dfdfdfdf", value);
    setCurrentDistrict(null);
    form.setFieldsValue({ district: null }); // 시/군/구 select 필드를 초기화
  };

  return (
    <Form form={form} onFinish={handleOnSubmit}>
      <Grid container my={2} columnSpacing={2}>
        <Grid item xs={12} md={4} lg={2}>
          <FormItem label="나이">
            <MDBox display="flex" alignItems="center" gap={1}>
              <FormItem
                name="ageStart"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && !getFieldValue("ageEnd")) {
                        return Promise.resolve();
                      }
                      if (value && !getFieldValue("ageEnd")) {
                        return Promise.reject(new Error("끝 나이를 입력하세요."));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber min={18} max={99} maxLength={2} style={{ width: "100%" }} />
              </FormItem>
              <FormItem
                name="ageEnd"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && !getFieldValue("ageStart")) {
                        return Promise.resolve();
                      }
                      if (value && !getFieldValue("ageStart")) {
                        return Promise.reject(new Error("시작 나이를 입력하세요."));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber min={18} max={99} maxLength={2} style={{ width: "100%" }} />
              </FormItem>
            </MDBox>
          </FormItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormItem label="평점" name="userScore">
            <InputNumber min={0} max={5} maxLength={1} style={{ width: "100%" }} />
          </FormItem>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <FormItem label="근로횟수" name="workCount">
            <InputNumber min={0} max={5} maxLength={1} style={{ width: "100%" }} />
          </FormItem>
        </Grid>

        <Grid item xs={12} md={4} lg={6}>
          <FormItem label="이름" name={"search"}>
            <Input
              type="text"
              placeholder="이름, 전화번호, 이메일로 검색하세요."
              style={{ width: "100%" }}
              maxLength={20}
              showCount
            />
          </FormItem>
        </Grid>
        <Grid item xs={12} md={8} lg={6} spacing={2} container>
          <Grid item xs={6}>
            <FormItem name="city" label="시/도">
              <Select
                style={{ minWidth: 150 }}
                value={currentCity}
                placeholder="시/도 선택"
                options={[{ label: "전체", value: null }, ...cityList]}
                onChange={handleCityFilter}
              />
            </FormItem>
          </Grid>

          <Grid item xs={6}>
            <FormItem name="district" label="시/군/구">
              <Select
                style={{ minWidth: 150 }}
                value={currentDistrict}
                placeholder="시/도 선택"
                options={[{ label: "전체", value: null }, ...districtList]}
                onChange={setCurrentDistrict}
              />
            </FormItem>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MDButton color="info" size="small" type="submit">
            조회
          </MDButton>
        </Grid>
      </Grid>
    </Form>
  );
}

export default UserSearchForm;
