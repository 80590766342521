import { ColumnsType } from "antd/es/table";

import { Icon } from "@mui/material";
import { IExpWorkList } from "apis/response";
import MDBox from "components/MDBox";
import { calculateAge } from "utils/utilDate";
import { inputToPhone } from "utils/PhoneString";
import RenderUserScore from "components/RenderUserScore";

const tableColumn: ColumnsType<IExpWorkList> = [
  {
    title: "이름",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },
  {
    title: "평점",
    dataIndex: "userScore",
    key: "userScore",
    ellipsis: true,
    render: RenderUserScore,
  },
  {
    title: "나이",
    dataIndex: "birth",
    key: "birth",
    ellipsis: true,
    render: (birth: string) => calculateAge(birth),
  },
  {
    title: "혈압",
    dataIndex: "bloodPressure",
    key: "bloodPressure",
    ellipsis: true,
  },
  {
    title: "연락처",
    dataIndex: "phone",
    key: "phone",
    ellipsis: true,
    render: (phone: string) => inputToPhone(phone),
  },
  {
    title: "계좌번호",
    dataIndex: "bankAccount",
    key: "bankAccount",
    ellipsis: true,
  },
  {
    title: "은행명",
    dataIndex: "bankName",
    key: "bankName",
    ellipsis: true,
  },
  {
    title: "예금주",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },
  {
    title: "출근현장",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
  },
];
export { tableColumn };
