import { ColumnsType } from "antd/es/table";

import { IReviews } from "apis/response";

const tableColumn: ColumnsType<IReviews> = [
  {
    title: "인력사무소",
    dataIndex: "workCreatorName",
    key: "workCreatorName",
    ellipsis: true,
  },
  {
    title: "공고제목",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    ellipsis: true,
  },
  {
    title: "만족도 평가",
    dataIndex: "score",
    key: "score",
    ellipsis: true,
    render: (score) => {
      switch (score) {
        case 1:
          return "불만족";
        case 2:
          return "보통";
        case 3:
          return "만족";
        default:
          return "미평가";
      }
    },
  },
];
export { tableColumn };
