import { Card } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IBanners } from "apis/response";
import { paramsFilter } from "utils/ParamsFilter";
import Apis from "apis/remotes";

function BannerList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [banners, setBanners] = useState<IBanners[]>([]);
  const [total, setTotal] = useState(0);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/member-management/banner-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
    setCurrentPage(page);
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/member-management/banner-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };
  useEffect(() => {
    Apis.getBanners({ page: currentPage, perPage: 10, search, sort, order })
      .then((resolve) => {
        setBanners(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox py={3} display="flex" justifyContent="space-between">
          {/* <MDButton variant="outlined" color="dark">
            엑셀 다운
          </MDButton> */}
          <Link to={"/member-management/new-banner"}>
            <MDButton variant="gradient" color="info">
              배너 추가
            </MDButton>
          </Link>
        </MDBox>

        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.bannerId}
            scroll={{ x: "max-content" }}
            dataSource={banners}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/member-management/banner-details/${record.bannerId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default BannerList;
