import { ColumnsType } from "antd/es/table";
import { IEmployments } from "apis/response";
import { inputToPhone } from "utils/PhoneString";

const tableColumn: ColumnsType<IEmployments> = [
  {
    title: "인력사무소명",
    dataIndex: "employmentName",
    key: "employmentName",
    ellipsis: true,
  },
  {
    title: "담당자",
    dataIndex: "managerName",
    key: "managerName",
    ellipsis: true,
  },
  {
    title: "연락처",
    dataIndex: "phone",
    key: "phone",
    ellipsis: true,
    render: (phone: string) => inputToPhone(phone),
  },
  {
    title: "상태",
    dataIndex: "status",
    key: "status",
    ellipsis: true,
  },
];
export { tableColumn };
