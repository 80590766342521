import { Card, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { IEmploymentForm } from "apis/response";
import Apis from "apis/remotes";

import FormItem from "antd/es/form/FormItem";
import MDButton from "components/MDButton";
// import Password from "antd/es/input/Password";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { handleInput } from "utils/PhoneString";
import Password from "antd/es/input/Password";

function UpdateEmployment(): JSX.Element {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();

  const [passwordForm] = useForm();
  const [loading, setLoading] = useState(false);

  const putUpdateWork = (formData: IEmploymentForm) => {
    // 폼 요소에서 employmentName, managerName, memo, email과 password 값을 가져오기
    setLoading(true);
    console.log(formData);
    Apis.putEmployment(id, { ...formData, role: "employ" })
      .then((resolve) => {
        message.success("인력사무소 수정이 완료되었습니다.");
        navigate(`/member-management/employment-details/${id}`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // putEmploymentPasswordById

  const handleUpdatePasswordForm = async (formData: FormData) => {
    try {
      setLoading(true);
      console.log(formData);
      await Apis.putEmploymentPasswordById(id, formData)
        .then((resolve) => {
          message.success("비밀번호가 변경되었습니다.");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      passwordForm.resetFields();
      setLoading(false);
    }
  };

  useEffect(() => {
    Apis.getEmploymentById(id)
      .then((resolve) => {
        form.setFieldsValue(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              labelCol={{ span: 5 }}
              style={{ maxWidth: 600 }}
              form={form}
              onFinish={putUpdateWork}
              autoComplete="off"
            >
              <FormItem label="인력사무소 업체명" name="employmentName">
                <Input type="text" />
              </FormItem>

              <FormItem label="인력사무소 담당자" name="managerName">
                <Input type="text" />
              </FormItem>
              <FormItem label="인력사무소 연락처" name="phone">
                <Input type="tel" onInput={handleInput} maxLength={11} />
              </FormItem>
              {/* <FormItem label="로그인 이메일" name="email">
                <Input type="email" />
              </FormItem> */}
              {/* <FormItem label="비밀번호" name="password">
                <Password type="password" />
              </FormItem> */}
              <FormItem label="메모" name="memo">
                <Input.TextArea />
              </FormItem>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  수정
                </MDButton>
              </MDBox>
            </Form>

            <Divider />
            <Form
              labelCol={{ span: 5 }}
              style={{ maxWidth: 600 }}
              form={passwordForm}
              onFinish={handleUpdatePasswordForm}
            >
              <FormItem
                label="새 비밀번호"
                name="newPassword"
                rules={[
                  { required: true, message: "새 비밀번호를 입력하세요." },
                  { min: 8, message: "비밀번호는 최소 8자리입니다." },
                  { max: 16, message: "비밀번호는 최대 16자리입니다." },
                  {
                    pattern: /^(?=.*[!@#$%^&*])(?=.*[0-9])/,
                    message: "비밀번호는 특수문자와 숫자를 포함해야 합니다.",
                  },
                ]}
              >
                <Password max={16} />
              </FormItem>
              <FormItem
                label="새 비밀번호 확인"
                name="newPasswordCheck"
                rules={[
                  { required: true, message: "새 비밀번호를 입력하세요." },
                  { min: 8, message: "비밀번호는 최소 8자리입니다." },
                  { max: 16, message: "비밀번호는 최대 16자리입니다." },
                  {
                    pattern: /^(?=.*[!@#$%^&*])(?=.*[0-9])/,
                    message: "비밀번호는 특수문자와 숫자를 포함해야 합니다.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("비밀번호가 일치하지 않습니다."));
                    },
                  }),
                ]}
              >
                <Password max={16} />
              </FormItem>
              <MDBox mt={4} mb={1}>
                <MDButton
                  fullWidth
                  variant="gradient"
                  color="dark"
                  type="submit"
                  disabled={loading}
                >
                  비밀번호 변경
                </MDButton>
              </MDBox>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default UpdateEmployment;
