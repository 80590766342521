import { ColumnsType } from "antd/es/table";
import MDBadge from "components/MDBadge";

import { convertDateToYYYYDotMMDotDD } from "utils/utilDate";
import { IGetAdministrators } from "apis/response";
import { inputToPhone } from "utils/PhoneString";

const tableColumn: ColumnsType<IGetAdministrators> = [
  {
    title: "이름",
    dataIndex: "nickName",
    ellipsis: true,
  },
  {
    title: "연락처",
    dataIndex: "phone",
    ellipsis: true,
    render: (phone: string) => inputToPhone(phone),
  },
  {
    title: "권한",
    dataIndex: "role",
    render(value) {
      switch (value) {
        case "master":
          return <MDBadge badgeContent="Admin" size="xs" container color="secondary" />;
        case "user":
          return <MDBadge badgeContent="User" size="xs" container color="info" />;
        default:
          return <MDBadge badgeContent="Not" size="xs" container color="warning" />;
      }
    },
    ellipsis: true,
  },
];
export { tableColumn };
