import { ColumnsType } from "antd/es/table";
import { IReportChat } from "apis/response";

const tableColumn: ColumnsType<IReportChat> = [
  {
    title: "신고자",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },
  {
    title: "신고 대상",
    dataIndex: "targetName",
    key: "targetName",
    ellipsis: true,
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    ellipsis: true,
  },
  {
    title: "신고 사유",
    dataIndex: "reason",
    key: "reason",
    ellipsis: true,
  },
  {
    title: "신고 시간",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
  },
];

export { tableColumn };
