import { Card, Grid, Icon } from "@mui/material";
import { Form, InputNumber, Pagination, PaginationProps, Radio, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import Apis from "apis/remotes";
import { IExpWorkList } from "apis/response";
import { paramsFilter } from "utils/ParamsFilter";
import MDBadgeDot from "components/MDBadgeDot";
import FormItem from "antd/es/form/FormItem";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function WorkerList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const name = params.get("name");
  const ageStart = params.get("ageStart");
  const ageEnd = params.get("ageEnd");
  const bloodPressure = params.get("bloodPressure");
  const scoreStart = params.get("scoreStart");
  const scoreEnd = params.get("scoreEnd");
  const page = params.get("page");
  console.log("ageStart", ageStart);
  console.log("bloodPressure", bloodPressure);
  console.log("scoreStart", scoreStart);

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [expWorks, setExpWorks] = useState<IExpWorkList[]>([]);
  const [total, setTotal] = useState(0);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/labor-management/worker-list${paramsFilter({
        name,
        ageStart,
        ageEnd,
        bloodPressure,
        scoreStart,
        scoreEnd,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/labor-management/worker-list${paramsFilter({
        name: text,
        ageStart,
        ageEnd,
        bloodPressure,
        scoreStart,
        scoreEnd,
        page: 1,
      })}`
    );
  };

  /** 필터 제거 */
  const removeFilter = (filterType: "age" | "blood" | "score") => {
    let result;
    switch (filterType) {
      case "age":
        result = { bloodPressure, scoreStart, scoreEnd };
        break;
      case "blood":
        result = { ageStart, ageEnd, scoreStart, scoreEnd };
        break;
      case "score":
        result = { ageStart, ageEnd, bloodPressure };
        break;
      default:
        result = {};
        break;
    }
    setCurrentPage(1);
    navigate(
      `/labor-management/worker-list${paramsFilter({
        name,
        ...result,
        page: 1,
      })}`
    );
  };

  /** 나이 필터 적용 */
  const handleAgeFilter = (ageRange: any) => {
    /** ageStart:23 ~ ageEnd:40 */
    const currentYear = new Date().getFullYear();
    /** convertAgeStart:2000   convertAgeEnd:1984 */
    const { ageStart, ageEnd } = ageRange;
    const convertAgeStart = currentYear - ageStart;
    const convertAgeEnd = currentYear - ageEnd;
    setCurrentPage(1);
    navigate(
      `/labor-management/worker-list${paramsFilter({
        name,
        ageStart: convertAgeEnd,
        ageEnd: convertAgeStart,
        bloodPressure,
        scoreStart,
        scoreEnd,
        page: 1,
      })}`
    );
  };
  /** 혈압 필터 적용 */
  const handleBloodPressure = (data: any) => {
    const { bloodPressure } = data;

    setCurrentPage(1);
    navigate(
      `/labor-management/worker-list${paramsFilter({
        name,
        ageStart,
        ageEnd,
        bloodPressure: bloodPressure,
        scoreStart,
        scoreEnd,
        page: 1,
      })}`
    );
  };
  /** 평점 필터 적용 */
  const handleScore = (scoreForm: any) => {
    const { scoreStart, scoreEnd } = scoreForm;
    console.log(scoreStart, scoreEnd);

    setCurrentPage(1);
    navigate(
      `/labor-management/worker-list${paramsFilter({
        name,
        ageStart,
        ageEnd,
        bloodPressure,
        scoreStart: scoreStart,
        scoreEnd: scoreEnd,
        page: 1,
      })}`
    );
  };
  useEffect(() => {
    Apis.getExpWorkList({
      page: currentPage,
      perPage: 10,
      name,
      ageStart,
      ageEnd,
      bloodPressure,
      scoreStart,
      scoreEnd,
    })
      .then((resolve) => {
        setExpWorks(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, name, ageStart, ageEnd, bloodPressure, scoreStart, scoreEnd]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3} spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Search
              maxLength={30}
              placeholder="이름 및 전화번호로 검색하세요."
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Form onFinish={handleAgeFilter}>
              <MDBox display="flex" width={"100%"} gap={1}>
                <FormItem>
                  <MDButton color="info" size="small" type="submit">
                    나이
                  </MDButton>
                </FormItem>

                <FormItem
                  name="ageStart"
                  rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
                >
                  <InputNumber min={18} max={99} maxLength={2} style={{ width: "60px" }} />
                </FormItem>

                <FormItem
                  name="ageEnd"
                  rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
                >
                  <InputNumber min={18} max={99} maxLength={2} style={{ width: "60px" }} />
                </FormItem>
              </MDBox>
            </Form>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Form onFinish={handleBloodPressure}>
              <MDBox display="flex" width={"100%"} gap={1}>
                <FormItem>
                  <MDButton color="info" size="small" type="submit">
                    혈압
                  </MDButton>
                </FormItem>
                <FormItem
                  name="bloodPressure"
                  rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
                >
                  <Radio.Group
                    options={[
                      { value: "정상", label: "정상" },
                      { value: "혈압", label: "비정상" },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </FormItem>
              </MDBox>
            </Form>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <Form onFinish={handleScore}>
              <MDBox display="flex" width={"100%"} gap={1}>
                <FormItem>
                  <MDButton color="info" size="small" type="submit">
                    평점
                  </MDButton>
                </FormItem>
                <FormItem
                  name="scoreStart"
                  rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
                >
                  <InputNumber min={0} max={5} maxLength={1} style={{ width: "70px" }} />
                </FormItem>
                <FormItem
                  name="scoreEnd"
                  rules={[{ required: true, message: "빈 값일 수 없습니다." }]}
                >
                  <InputNumber min={0} max={5} maxLength={1} style={{ width: "70px" }} />
                </FormItem>
              </MDBox>
            </Form>
          </Grid>
        </Grid>
        <MDBox display={"flex"} alignItems={"center"} gap={1} mb={2}>
          {bloodPressure !== null && (
            <MDButton
              size="small"
              variant="outlined"
              color="dark"
              endIcon={<HighlightOffIcon />}
              onClick={() => removeFilter("blood")}
            >
              혈압
            </MDButton>
          )}
          {ageEnd !== null && (
            <MDButton
              size="small"
              variant="outlined"
              color="dark"
              endIcon={<HighlightOffIcon />}
              onClick={() => removeFilter("age")}
            >
              나이
            </MDButton>
          )}
          {scoreEnd !== null && (
            <MDButton
              size="small"
              variant="outlined"
              color="dark"
              endIcon={<HighlightOffIcon />}
              onClick={() => removeFilter("score")}
            >
              평점
            </MDButton>
          )}
        </MDBox>

        <Card>
          <Table
            title={() => <MDBadgeDot badgeContent={"근로자목록"} color="warning" size="lg" />}
            pagination={false}
            // showHeader={false}
            rowKey={(record) => record.userId}
            scroll={{ x: "max-content" }}
            dataSource={expWorks}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/labor-management/worker-detail/${record.userId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                showTotal={(total) => `총 ${total.toLocaleString()}명`}
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default WorkerList;
