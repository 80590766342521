import { Card, Divider } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import ListItem from "./components/ListItem";
import { useEffect, useState } from "react";
import { IReportChat } from "apis/response";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";

function ChatReport(): JSX.Element {
  const { id } = useParams();
  const [reportChat, setReportChat] = useState<IReportChat>();

  useEffect(() => {
    Apis.getReportChat(id)
      .then((resolve) => {
        setReportChat(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <ListItem label={"신고 대상"} values={reportChat?.targetName} />
            <ListItem label={"신고자"} values={reportChat?.name} />
            <Divider />
            <ListItem label={"본문 내용"} values={reportChat?.content} />
            <ListItem label={"신고 사유"} values={reportChat?.reason} />
            <ListItem label={"신고 시간"} values={reportChat?.createdAt} />
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ChatReport;
