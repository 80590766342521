import { Card, Divider, Grid } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { IBuddy, IGetUserById } from "apis/response";
import { App, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { calculateAge } from "utils/utilDate";
import UserProfileCard from "./components/UserProfileCard";
import BuddyCell from "./components/BuddyCell";
import MDImage from "components/MDImage";
import { inputToPhone } from "utils/PhoneString";
import { genderString } from "utils";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import DownloadFile from "./components/DownLoadFile";

const getFileType = (url: string) => {
  const extension = url.split(".").pop()?.toLowerCase(); // 확장자를 소문자로 변환
  return extension === "jpg" || extension === "jpeg" || extension === "png" ? extension : "unknown";
};
function MemberDetails(): JSX.Element {
  const { message } = App.useApp();
  const { id } = useParams();
  const [user, setUser] = useState<IGetUserById>();

  const [newInsurance, setNewInsurance] = useState({
    insuranceDay: [0, 0, 0, 0],
    insuranceAmountAvg: [0, 0, 0, 0],
  });

  const [buddy, setBuddy] = useState<IBuddy[]>([]);
  const [buddyPage, setBuddyPage] = useState<number>(1);
  const [buddyTotal, setBuddyTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [form] = useForm();

  useEffect(() => {
    Apis.getUserById(id)
      .then((resolve) => {
        setUser(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
    Apis.getUserInsuranceById(id)
      .then((resolve) => {
        const data = resolve.data;
        const insuranceDays = [
          Number(data.threeMonthInsuranceDay),
          Number(data.sixMonthInsuranceDay),
          Number(data.oneYearInsuranceDay),
          Number(data.threeYearInsuranceDay),
        ];

        console.log(insuranceDays);
        const insuranceAmounts = [
          Number(data.threeMonthInsuranceAmount),
          Number(data.sixMonthInsuranceAmount),
          Number(data.oneYearInsuranceAmount),
          Number(data.threeYearInsuranceAmount),
        ];

        const insuranceAmountAvg = insuranceDays.map((days, index) => {
          const avg = days > 0 && insuranceAmounts[index] > 0 ? insuranceAmounts[index] / days : 0;
          return isNaN(avg) ? 0 : Math.floor(avg); // Ensure NaN is converted to 0
        });

        setNewInsurance({ insuranceDay: insuranceDays, insuranceAmountAvg });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    setLoading(true);
    Apis.getBuddy(id, { page: buddyPage, perPage: 10 })
      .then((resolve) => {
        setBuddy(resolve.data.data);
        setBuddyTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, buddyPage]);

  const handleSendNoticeMessage = (formData: FormData) => {
    setPending(true);
    Apis.postChatNoticeById(id, formData)
      .then((resolve) => {
        message.success("공지 전송 완료");
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const renderSendNoticeMessage = () => {
    if (user?.status === "delete") {
      return "탈퇴한 계정은 공지채팅을 보낼 수 없습니다.";
    } else {
      return (
        <Form form={form} onFinish={handleSendNoticeMessage}>
          <Grid container gap={1}>
            <Grid item xs={8} sm={6}>
              <MDBox>
                <FormItem
                  name="content"
                  rules={[{ required: true, message: "메세지를 입력하세요." }]}
                >
                  <Input placeholder="공지 메시지를 입력해주세요" width={"300px"} maxLength={30} />
                </FormItem>
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={5}>
              <MDButton disabled={pending} size="small" type="submit" color="info">
                공지 전송
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      );
    }
  };

  const loadMore = () => {
    if (buddy.length < buddyTotal) {
      setBuddyPage((prev) => prev + 1);
    }
  };

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const listboxNode = event.currentTarget;
    const scrollPosition = listboxNode.scrollTop + listboxNode.clientHeight; // 현재 스크롤 위치
    const threshold = listboxNode.scrollHeight * 0.8; // 스크롤 높이의 80%

    if (scrollPosition >= threshold && loading === false) {
      loadMore();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Grid container>
          <Grid item sm={12} md={9}>
            <Grid container spacing={3} alignItems="center" p={5}>
              <Grid item>
                <MDAvatar alt="profile-image" size="xl" shadow="sm" />
              </Grid>
              <Grid item>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {user?.name}
                  </MDTypography>
                  {/* <MDTypography variant="button" color="text" fontWeight="regular">
                    {user?.gender} / {user?.birth}
                  </MDTypography> */}
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={3} mb={1} ml={3} p={3}>
              <MDBox display="flex" flexDirection={"column"} justifyContent="space-between">
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  회원 정보
                </MDTypography>
                <MDBox pt={2}>
                  <MDTypography variant="button" color="text" fontWeight="light">
                    {"자기소개"}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Grid container spacing={1}>
                <Grid item sm={12} md={6}>
                  <UserProfileCard
                    info={{
                      이름: user?.name,
                      가입일자: user?.createdAt ? dayjs(user.createdAt).format("YYYY-MM-DD") : "",
                      성별: genderString(user?.gender),
                      생년월일: `${user?.birth ?? ""} / ${calculateAge(user?.birth)}`,
                      연락처: inputToPhone(user?.phone ?? ""),
                      이메일: user?.email,
                      주소: user?.firstAddress,
                      상세주소: user?.secondAddress,
                      지역:
                        (user?.city === "" ? "미입력" : user?.city) +
                        "/" +
                        (user?.district === "" ? "미입력" : user?.district),
                    }}
                    shadow={false}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <UserProfileCard
                    info={{
                      작업경험: `${user?.career ?? ""}`,
                      계좌정보: `${user?.bankName ?? ""} | ${user?.bankAccount ?? ""}`,
                      혈액형: `${user?.bloodRh ?? ""}${user?.bloodType ?? ""}형`,
                      혈압: `${user?.bloodPressure ?? ""} | 이완: ${
                        user?.bloodPressureDia ?? ""
                      } | 수축: ${user?.bloodPressureSys ?? ""}`,
                    }}
                    shadow={false}
                  />
                  <Divider />
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    공지채팅 전송:
                  </MDTypography>
                  {renderSendNoticeMessage()}
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item sm={12} md={3} mt={5}>
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                단짝 목록
              </MDTypography>
            </MDBox>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              style={{ overflowY: "scroll", height: "500px" }}
              onScroll={handleOnScroll}
            >
              {buddy.map((buddy) => {
                return <BuddyCell key={buddy.userId} name={buddy.name} image={buddy.profile} />;
              })}
              {buddy.length === 0 && "버디가 없습니다."}
            </MDBox>
          </Grid>
        </Grid>

        <MDBox border="2px solid black" borderRadius="5px" overflow="hidden" mx={2}>
          <MDBox display="flex" bgColor="#f5f5f5">
            <MDBox width="20%" p={2} border={1}>
              <MDTypography fontWeight="bold">구분</MDTypography>
            </MDBox>
            <MDBox width="20%" p={2} border={1}>
              <MDTypography fontWeight="bold">3개월</MDTypography>
            </MDBox>
            <MDBox width="20%" p={2} border={1}>
              <MDTypography fontWeight="bold">6개월</MDTypography>
            </MDBox>
            <MDBox width="20%" p={2} border={1}>
              <MDTypography fontWeight="bold">1년</MDTypography>
            </MDBox>
            <MDBox width="20%" p={2} border={1}>
              <MDTypography fontWeight="bold">3년</MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex">
            <MDBox width="20%" p={2} border={1}>
              <MDTypography>근로일수</MDTypography>
            </MDBox>
            {newInsurance.insuranceDay.map((days, index) => (
              <MDBox key={index} width="20%" p={2} border={1}>
                <MDTypography>{days}</MDTypography>
              </MDBox>
            ))}
          </MDBox>
          <MDBox display="flex">
            <MDBox width="20%" p={2} border={1}>
              <MDTypography>평균단가 (임금총액/근로일수)</MDTypography>
            </MDBox>
            {newInsurance.insuranceAmountAvg.map((amount, index) => (
              <MDBox key={index} width="20%" p={2} border={1}>
                <MDTypography>{amount.toLocaleString()}</MDTypography>
              </MDBox>
            ))}
          </MDBox>
        </MDBox>
        <Divider />
        <Grid container justifyContent={"center"} spacing={2} mb={5}>
          <Grid
            item
            xs={12}
            md={6}
            xl={6}
            style={{ textAlign: "center" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                기초안전보건교육 이수증
              </MDTypography>
              {user?.safetyCertificate && (
                <DownloadFile
                  path={user.safetyCertificate}
                  fileName={`${dayjs(new Date()).format("YYYY-MM-DD")} ${
                    user?.name
                  } 기초안전보건교육 이수증.${getFileType(user.safetyCertificate)}`}
                />
              )}
            </MDBox>

            <MDImage
              image={user?.safetyCertificate}
              alt="content_img"
              width={"300px"}
              height={"300px"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={6}
            style={{ textAlign: "center" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                신분증
              </MDTypography>
              {user?.idCard && (
                <DownloadFile
                  path={user.idCard}
                  fileName={`${dayjs(new Date()).format("YYYY-MM-DD")} ${
                    user?.name
                  } 신분증.${getFileType(user.idCard)}`}
                />
              )}
            </MDBox>

            <MDImage image={user?.idCard} alt="content_img" width={"300px"} height={"300px"} />
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}

export default MemberDetails;
