import { Card, Divider } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import ListItem from "./components/ListItem";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { message } from "antd";
import { INotice } from "apis/response";

function NoticeDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [notice, setNotice] = useState<INotice>();
  /** 공지 삭제 버튼 클릭 */
  const handleDeleteNotice = () => {
    Apis.deleteNotice(id)
      .then((resolve) => {
        navigate("/notice-management/notice-list");
        message.success("공지가 제거되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /** 공지 수정 버튼 클릭 */
  const handleNavigateUpdateNotice = () => {
    navigate(`/notice-management/update-notice-details/${id}`);
  };
  useEffect(() => {
    Apis.getNoticeById(id)
      .then((resolve) => {
        setNotice(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <MDBox mb={2} lineHeight={1} display={"flex"} justifyContent={"space-between"}>
              <MDTypography variant="h3">{notice?.title}</MDTypography>
              <MDButton color="info" size="small" onClick={() => handleNavigateUpdateNotice()}>
                공지 수정
              </MDButton>
            </MDBox>
            <MDBox opacity={0.3}>
              <Divider />
            </MDBox>
            <MDBox>
              <ListItem label={"본문내용"} values={notice?.content} />
              <ListItem label={"작성시간"} values={notice?.createdAt} />
            </MDBox>
          </MDBox>
          <MDBox p={3} display={"flex"} justifyContent={"end"} gap={2}>
            <MDButton color="error" size="small" onClick={() => handleDeleteNotice()}>
              공지 삭제
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default NoticeDetails;
