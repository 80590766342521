import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ListItem from "./components/ListItem";
import { convertDateToYYYYDotMMDotDD } from "utils/utilDate";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { IQnaById } from "apis/response";
import { Form, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/es/form/Form";

function QNADetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [qna, setQna] = useState<IQnaById>();
  const [form] = useForm();

  const handleUpdateComment = (comment: FormData) => {
    console.log(comment);
    if (qna.questionCommentId) {
      Apis.putQnaById(qna?.questionCommentId, comment)
        .then((resolve) => {
          message.success("답변 수정");
          navigate(`/cs-management/qna-list`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Apis.postQnaById(id, comment)
        .then((resolve) => {
          message.success("답변 등록");
          navigate(`/cs-management/qna-list`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDeleteComment = () => {
    if (qna?.questionCommentId) {
      Apis.deleteQnaById(qna?.questionCommentId)
        .then((resolve) => {
          message.success("답변 삭제");
          navigate(`/cs-management/qna-list`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    Apis.getQnaById(id)
      .then((resolve) => {
        console.log(resolve.data);
        form.setFieldsValue(resolve.data);
        setQna(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <MDBox>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <ListItem label={"문의요청"} values={qna?.title} />

                  <ListItem
                    label={"작성일"}
                    values={convertDateToYYYYDotMMDotDD(qna?.commentCreatedAt)}
                  />
                  <ListItem
                    label={"업데이트일"}
                    values={convertDateToYYYYDotMMDotDD(qna?.commentUpdatedAt)}
                  />
                  <MDBox opacity={0.3}>
                    <Divider />
                  </MDBox>
                </Grid>
              </Grid>

              <ListItem label={"내용"} values={qna?.content} />
            </MDBox>
            <MDBox opacity={0.3}>
              <Divider />
            </MDBox>
            <Form form={form} onFinish={handleUpdateComment}>
              <FormItem name="comment" rules={[{ required: true, message: "내용을 입력하세요." }]}>
                <TextArea showCount maxLength={255} />
              </FormItem>

              <MDBox my={3}>
                <MDButton type="submit" color="info">
                  답변등록
                </MDButton>
              </MDBox>
            </Form>
          </MDBox>
        </Card>
        <MDBox>
          {qna?.questionCommentId ? (
            <MDBox my={2} display={"flex"} justifyContent={"end"}>
              <MDButton
                color="error"
                variant="gradient"
                fontSize="small"
                onClick={() => handleDeleteComment()}
              >
                답변삭제
              </MDButton>
            </MDBox>
          ) : null}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default QNADetails;
