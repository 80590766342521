import Apis from "apis/remotes";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";

const toDataURL = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};
const downloadFile = async (url: string, fileName?: string) => {
  try {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = fileName ?? "download";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.log(error);
  }
};

const DownloadFile = ({ path, fileName }: { path: string; fileName: string }) => {
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  useEffect(() => {
    // 서버에서 Presigned URL을 가져옴
    Apis.getFileDownLoad({ path })
      .then((response) => {
        setDownloadUrl(response.data.url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClickDownloadButton = () => {
    if (downloadUrl) {
      downloadFile(downloadUrl, fileName);
    }
  };
  return (
    <div>
      {downloadUrl ? (
        <MDButton onClick={() => handleClickDownloadButton()}>다운로드</MDButton>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default DownloadFile;
