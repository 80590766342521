import image from "antd/es/image";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

// Images
import kal from "assets/images/kal-visuals-square.jpg";

interface Props {
  image: string;
  name: string;
  //   action: {
  //     type: "external" | "internal";
  //     route: string;
  //     color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  //     label: string;
  //   };
}
function BuddyCell({ image, name }: Props): JSX.Element {
  return (
    <MDBox key={name} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        <MDAvatar src={image} alt="something here" shadow="md" />
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

BuddyCell.defaultProps = {
  image: kal,
  name: "Sophie B.",
  description: "2024-01-05",
};
export default BuddyCell;
