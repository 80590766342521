import { Card, Divider } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { IChatNotice } from "apis/response";
// @mui icons
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { Pagination, PaginationProps, Radio, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
import { tableColumn } from "./components/data";
import MDButton from "components/MDButton";

function ChatNoticeList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");
  const [sendAll, setSendAll] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [chatNotices, setChatNotices] = useState<IChatNotice[]>([]);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/chat-management/chat-notice-list${paramsFilter({
        page,
      })}`
    );
    setCurrentPage(page);
  };
  useEffect(() => {
    Apis.getChatNotice({ sendAll, page: currentPage, perPage: 10 })
      .then((resolve) => {
        setChatNotices(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, sendAll]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox py={3} display="flex" justifyContent="space-between">
          <Radio.Group
            defaultValue={true}
            options={[
              { value: true, label: "전체" },
              { value: false, label: "개별" },
            ]}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => {
              setSendAll(e.target.value);
            }}
          />
          <Link to={"/chat-management/create-chat-notice"}>
            <MDButton variant="gradient" color="info">
              전체공지 전송
            </MDButton>
          </Link>
        </MDBox>
        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.messageId}
            scroll={{ x: "max-content" }}
            dataSource={chatNotices}
            columns={tableColumn}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
  return <div></div>;
}

export default ChatNoticeList;
